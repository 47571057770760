import Image from "next/image";
import { useContext } from "react";
import { LocalizationContext } from "../lib/context";

export default function HaveQuestions({
  email,
  title,
  text,
}: {
  email: string;
  title?: string;
  text?: string;
}) {
  const { uiGeneral } = useContext(LocalizationContext).localeStrings;
  return (
    <div className="settings__bottom">
      <h4 className="settings__bottom--primaryText">
        {title ? title : uiGeneral.haveQuestions}
      </h4>

      <div>
        <Image src="/assets/email.png" alt="" width={15} height={12} />
        <a
          href={`mailto:${email}`}
          className="settings__underLineText"
          children={text ? text : uiGeneral.writeToUs}
        />
      </div>
    </div>
  );
}
