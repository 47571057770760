import { Box, Button } from "@mui/material";
import Link from "next/link";
import DeleteIcon from "@mui/icons-material/Delete";
import { useContext } from "react";
import { CompareItemsContext, LocalizationContext } from "../lib/context";

export function CompareItemsBar() {
  const { localeStrings } = useContext(LocalizationContext);
  const { compareItems, setCompareItems } = useContext(CompareItemsContext);

  return (
    <Box sx={{ display: { xs: "none", md: "block" } }}>
      {!!compareItems?.length && (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            height: "3.125rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bottom: 0,
            zIndex: 100,
            boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.1)",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            backdropFilter: "blur(5px)",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Link
              href={
                "/compare?" +
                compareItems
                  .map((item, index) => {
                    return `${index}=${item}&`;
                  })
                  .join("")
                  .slice(0, -1)
              }
            >
              <Button variant="text" color="secondary" size="large">
                {localeStrings.uiGeneral.compare} ({compareItems.length})
              </Button>
            </Link>
            <Button
              color="textColors"
              startIcon={<DeleteIcon />}
              onClick={() => {
                setCompareItems([]);
              }}
            >
              {localeStrings.uiGeneral.clear}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
