import Link from "next/link";

export default function LargeNavLogo() {
  return (
    <div className="flex">
      <Link href="/">
        <div
          className="pointer"
          style={{
            background: "url('/assets/logo_beta.svg') no-repeat left center",
            backgroundSize: "contain",
            width: 150,
            height: 40,
          }}
        />
      </Link>
    </div>
  );
}
