import { ThemeProvider } from "@mui/material/styles";
import theme from "../styles/theme";
import {
  CompareItemsContext,
  DronersContext,
  EventsContext,
  LocalizationContext,
  PlaceContext,
  SelectedDroner,
  SelectedSkillContext,
  ShareLinkObject,
  UserContext,
} from "./context";
import { useState } from "react";
import { useUserData } from "../hooks/useUserData";
import { useCompareItems } from "../hooks/useCompareItems";

export default function ContextProvider({ children, props }) {
  const localeCmsContent = props.cmsContent[props.router.locale];
  /**
   * deprecated
   * @type {{userData: any, actDroner: any, dronerModel: any, loading: boolean, user: User | null | undefined}}
   */
  const { dronerModel } = useUserData();
  const [place, setPlace] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [allDroners, setAllDroners] = useState([]);
  const [selectedDroner, setSelectedDroner] = useState(null);
  const [localeStrings] = useState({ ...localeCmsContent });
  const [shareLinkObject, setShareLinkObject] = useState(null);
  const [events, setEvents] = useState([]);

  const { compareItems, setCompareItems } = useCompareItems();

  return (
    <>
      <UserContext.Provider value={dronerModel}>
        <LocalizationContext.Provider value={{ localeStrings }}>
          <ThemeProvider theme={theme}>
            <DronersContext.Provider value={{ allDroners, setAllDroners }}>
              <SelectedDroner.Provider
                value={{ selectedDroner, setSelectedDroner }}
              >
                <CompareItemsContext.Provider
                  value={{ compareItems, setCompareItems }}
                >
                  <SelectedSkillContext.Provider
                    value={{ selectedSkills, setSelectedSkills }}
                  >
                    <ShareLinkObject.Provider
                      value={{ shareLinkObject, setShareLinkObject }}
                    >
                      <PlaceContext.Provider value={{ place, setPlace }}>
                        <EventsContext.Provider value={{ events, setEvents }}>
                          {children}
                        </EventsContext.Provider>
                      </PlaceContext.Provider>
                    </ShareLinkObject.Provider>
                  </SelectedSkillContext.Provider>
                </CompareItemsContext.Provider>
              </SelectedDroner.Provider>
            </DronersContext.Provider>
          </ThemeProvider>
        </LocalizationContext.Provider>
      </UserContext.Provider>
    </>
  );
}
