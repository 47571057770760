import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { smallModalCard } from "../styles/globalStyles";
import { LocalizationContext } from "../lib/context";
import { httpsCallable } from "firebase/functions";
import { auth, functions } from "../../api_config/firebase-configuration";
import { signInWithEmailAndPassword } from "firebase/auth";
import { setUserPersistence } from "../lib/global";
import { FacebookButton, GoogleButton, TwitterButton } from "./SocialButtons";
import { PasswordResetModal } from "./PasswordResetModal";
import Image from "next/image";
import HaveQuestions from "./HaveQuestions";
import { useRouter } from "next/router";

export default function LoginPopup({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: any;
}) {
  const { localeStrings } = useContext(LocalizationContext);
  const router = useRouter();
  const localizedPageContent = localeStrings.enterPage;
  const [openResetPass, setOpenResetPass] = useState(false);
  const [pwResetEmail, setPwResetEmail] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const resetPassFn = httpsCallable(functions, "sendgrid-passwordReset");
  const resetPass = () => {
    resetPassFn({
      email: pwResetEmail,
    }).catch((err) => {
      console.error(err);
    });
    setOpenResetPass(false);
    setPwResetEmail("");
  };

  const signIn = async () => {
    setErrorMessage("");

    const login = async () => {
      try {
        await signInWithEmailAndPassword(auth, email, password);
        setOpen(false);
        return null;
      } catch (e: any) {
        return e.code.split("/")[1].split("-").join(" ");
      }
    };
    // First we need to try to log in with the email first
    setErrorMessage("Incorrect email or password!");
    await login();
  };

  const register = async () => {
    setErrorMessage("");
    router.push("/register");
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box sx={{ ...smallModalCard }} className="login">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div className="login__header">
            <Image
              onClick={() => setOpen(false)}
              width="16"
              height="16"
              src="/assets/arrow-left.png"
              alt="arrow-left"
            />
            <h4 className="subtitle-5 text-text">
              {localizedPageContent.pageTitle}
            </h4>
          </div>
          <Divider />
          <div className="login__section">
            <h3 className="text-text mb-normal">
              {localizedPageContent.pageSubtitle}
            </h3>
            <FormControl variant="outlined">
              <div className="settingsInput">
                <label className="settings__secondaryText">Email</label>
                <div className="settingsInput__wrapper">
                  <TextField
                    type="email"
                    id="email"
                    onChange={(event: any) => setEmail(event.target.value)}
                    className="settingsInput__input"
                  />
                </div>
              </div>
            </FormControl>
            <FormControl variant="outlined">
              <div className="settingsInput">
                <label className="settings__secondaryText">
                  {localeStrings.uiGeneral.password}
                </label>

                <div className="settingsInput__wrapper">
                  <TextField
                    onChange={(event: any) => setPassword(event.target.value)}
                    type={showPassword ? "text" : "password"}
                    className="settingsInput__input"
                  />
                  <div className="settingsInput__wrapper--helper">
                    <Image
                      width={23.5}
                      height="15"
                      src="/assets/eyeIcon.png"
                      alt="eye icon"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </div>
                </div>
              </div>
            </FormControl>
            <div className="login__middle">
              <FormControlLabel
                control={
                  <Checkbox value="remember" className="login__checkbox" />
                }
                className="login__smallText"
                label="Remember me"
                onChange={(event: any) =>
                  setUserPersistence(event.target.checked)
                }
              />

              <FormControlLabel
                control={
                  <Checkbox value="remember" className="login__checkbox" />
                }
                className="login__smallText"
                label="Subscribe to our newsletter"
                // onChange={(event: any) =>
                //   setUserPersistence(event.target.checked)
                // }
              />
              {errorMessage && (
                <Typography variant="body1" color="error" sx={{ mx: "auto" }}>
                  {errorMessage}
                </Typography>
              )}

              <Box sx={{ display: "grid", rowGap: "8px" }}>
                <div className="login__terms">
                  <h5 className="caption">
                    By clicking on continue you accept the{" "}
                  </h5>
                  <h5 className="caption">
                    <a>Terms of use</a> and <a>Privacy policy</a>
                  </h5>
                </div>

                <button
                  type="submit"
                  data-variant="login"
                  onClick={() => signIn()}
                >
                  Login
                  {/* {localizedPageContent.pageTitle} */}
                </button>
                <Typography variant="body2" sx={{ my: 1, textAlign: "center" }}>
                  Or
                </Typography>
                <button
                  type="submit"
                  data-variant="login"
                  onClick={() => register()}
                  style={{ marginBottom: "30px" }}
                >
                  Register
                  {/* {localizedPageContent.pageTitle} */}
                </button>
              </Box>
            </div>
            <button
              className="link-hover"
              onClick={() => {
                setOpenResetPass(true);
              }}
              data-variant="text"
              data-color="text"
            >
              {localizedPageContent.forgotPasswordButton}?
            </button>
          </div>
          <Divider />

          <Box className="login__section" sx={{ pb: 4 }}>
            <GoogleButton
              setOpen={setOpen}
              action="login"
              text={localeStrings?.enterPage.googleLogInButton}
            />
            <FacebookButton
              setOpen={setOpen}
              action="login"
              text={localeStrings?.enterPage.facebookLogInButton}
            />
            <TwitterButton
              setOpen={setOpen}
              action="login"
              text={localeStrings?.enterPage.twitterLogInButton}
            />

            <HaveQuestions email={localeStrings?.errorPage?.mailToLink} />
          </Box>
        </Box>
        <Modal open={openResetPass} onClose={() => setOpenResetPass(false)}>
          <Box sx={{ ...smallModalCard, minWidth: 300 }}>
            <PasswordResetModal
              email={pwResetEmail}
              setEmail={setPwResetEmail}
              resetPass={resetPass}
            />
          </Box>
        </Modal>
      </Box>
    </Modal>
  );
}
