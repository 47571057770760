import { Box, Button, TextField } from "@mui/material";
import { dcContent } from "../../services/content";
import { LocalizationContext } from "../lib/context";
import { useContext } from "react";
import LockResetIcon from "@mui/icons-material/LockReset";

export function PasswordResetModal({ email, setEmail, resetPass }) {
  const { localeStrings } = useContext(LocalizationContext);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 2,
      }}
    >
      <div className="flex justify-content-center">
        <LockResetIcon
          sx={{
            fontSize: "6rem",
          }}
        />
      </div>
      <div className="headline-2 text-center text text mb-normal">
        {localeStrings.uiGeneral.forgotPasswordButton}
      </div>
      <div className="headline-6 text-center text text mb-normal">
        {localeStrings.uiGeneral.forgotPwModalText}
      </div>
      <div className={"flex col w-full gap-large"}>
        <TextField
          type="email"
          placeholder={dcContent.text.email}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          variant="contained"
          data-color="primary"
          size="small"
          onClick={resetPass}
          sx={{ mx: "auto" }}
        >
          {localeStrings.uiGeneral.sendButton}
        </Button>
      </div>
    </Box>
  );
}
