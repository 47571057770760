import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { useRouter } from "next/router";
import React, { SetStateAction, useContext, useState } from "react";
import { DbProvider } from "../../services/db/DbProvider";
import { LocalizationContext } from "../lib/context";

export default function NewsletterSubscriptionForm() {
  const { uiGeneral } = useContext(LocalizationContext).localeStrings;
  const [email, setEmail] = useState("");
  const [termsAndCondition, setTermsAndCondition] = useState(false);
  const router = useRouter();
  const isSerbian = router.locale === "sr-Latn-RS";

  const changeHandler = (e: { target: { value: SetStateAction<string> } }) => {
    setEmail(e.target.value);
  };

  const db = new DbProvider();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await db.addToArray(
        "emailAddresses",
        email,
        "newsletterEmails",
        "general"
      );
      setEmail("");
      setTermsAndCondition(false);
      alert("Subscribed successfully to our newsletter!");
    } catch (e) {
      console.error(e);
      alert("Error subscribing!");
    }
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className="footer__newsletter--inputBox">
        <input
          type="email"
          placeholder="Email..."
          value={email}
          onChange={changeHandler}
          autoComplete="email"
          required
        />
        <div className="footer__newsletter--subscribe">
          <Button
            variant="outlined"
            type="submit"
            sx={{
              height: "100%",
              borderRadius: "10px",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              color: "#2496CC",
              border: 0,
              backgroundColor: "#fff",
              width: "140px",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#3c52b2",
                border: 0,
              },
            }}
          >
            {uiGeneral.subscribeButton}
          </Button>
        </div>
      </div>
      <FormControlLabel
        sx={{ maxWidth: "300px", textAlign: "left", marginTop: "10px" }}
        control={
          <Checkbox
            required
            checked={termsAndCondition}
            onChange={() => setTermsAndCondition(!termsAndCondition)}
            sx={{
              color: "#fff",
              "&.Mui-checked": {
                color: "#fff",
              },
              pl: 0,
            }}
          />
        }
        label={
          <span className="footer__newsletter--labelText">
            I accept the Data{" "}
            <a href="https://dronecakes.com/assets/docs/GDPR_EN.pdf">Privacy</a>{" "}
            and{" "}
            <a href="https://dronecakes.com/assets/docs/General_Terms_And_Conditions_Official.pdf">
              General Terms and Conditions
            </a>
            .
          </span>
        }
      />
    </form>
  );
}
