import { useUserData } from "../hooks/useUserData";
import Link from "next/link";
import { Divider } from "@mui/material";
import { useContext } from "react";
import { LocalizationContext } from "../lib/context";
import { auth } from "../../api_config/firebase-configuration";
import { useRouter } from "next/router";
import { MergedNavbarItem } from "../types/mergedLocTypes";

export default function SmallMenuList({
  handleIconClick,
  navbarProfileMenuItems,
  navbarGeneralMenuItems,
}: {
  handleIconClick: any;
  navbarGeneralMenuItems: MergedNavbarItem[];
  navbarProfileMenuItems: MergedNavbarItem[];
}) {
  const { localeStrings } = useContext(LocalizationContext);
  const { user, actDroner } = useUserData();

  const router = useRouter();

  function handleLogOut() {
    handleIconClick();
    router.push("/");
    auth.signOut();
  }

  return (
    <div className="mx-normal">
      {navbarGeneralMenuItems.map((item: MergedNavbarItem, i) => (
        <div className="w-full" key={i}>
          {((!user && item.forLoggedOut) || (!!user && item.forLoggedIn)) && (
            <div onClick={handleIconClick} className="mb-huge">
              {item.title === "insurance" ? (
                <Link
                  target="_blank"
                  href="https://biztositas.dronecakes.com"
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                  className="text-text"
                >
                  <div
                    className="pointer subtitle-4"
                    style={{ color: "#4d4e4e", fontWeight: "bold" }}
                  >
                    {item.localizedText}
                  </div>
                </Link>
              ) : (
                <Link href={item.path}>
                  <div
                    className="pointer subtitle-4"
                    style={{ color: "#4d4e4e", fontWeight: "bold" }}
                  >
                    {item.localizedText}
                  </div>
                </Link>
              )}
            </div>
          )}
        </div>
      ))}
      <Divider className="w-full" />

      {navbarProfileMenuItems?.map(
        (item: MergedNavbarItem, i: number) =>
          ((!user && item?.forLoggedOut && !!item?.personal) ||
            (!!user && item?.forLoggedIn && !!item?.personal)) && (
            <div key={i} className="py-large" onClick={handleIconClick}>
              <Link
                href={
                  actDroner?.username
                    ? item?.title == "view profile"
                      ? item?.path + actDroner?.username
                      : item?.path
                    : "/" + router.locale + "/register"
                }
              >
                <div
                  className="pointer subtitle-4"
                  style={{ color: "#4d4e4e", fontWeight: "bold" }}
                >
                  {item?.localizedText}
                </div>
              </Link>
            </div>
          )
      )}

      {user && (
        <>
          <Divider className="w-full" />
          <div
            onClick={handleLogOut}
            className="pointer flex align-items-center text-center subtitle-4 my-huge text-primary"
          >
            {localeStrings.uiGeneral.logOutButton}
          </div>
        </>
      )}
    </div>
  );
}
