import { FirebaseDatabase } from "../../services/db/FirebaseDatabase";
import { DronerModel } from "../../repository/entities/DronerModel";
import keyBy from "lodash/keyBy";
import merge from "lodash/merge";
import values from "lodash/values";
import {
  finalJobStates,
  ModifiedJobStates,
  originalJobStates,
} from "../types/jobTypes";
import { NextRouter } from "next/router";

export function getEmbedLinkFromYoutubeLink(link: string) {
  const strToAdd = "https://www.youtube.com/embed/";
  const divider = "v=";
  const idArr = link.split(divider);
  let id = "";
  idArr.forEach((item: string, i: number) => {
    if (i) id += item;
    if (i > 1 && i < idArr.length - 1) id += divider;
  });
  return strToAdd + id;
}

export function getActLocation(handler: any) {
  let locationType;
  window.navigator.geolocation.getCurrentPosition(
    (r) => {
      locationType = { lat: r.coords.latitude, lng: r.coords.longitude };
      handler(locationType);
    },
    () => alert("Sorry, your location is currently not available.")
  );
}

export function jobBoardSort(jobs: any, dronerJobs: any) {
  return jobs
    ?.map((job: any) => ({
      ...job,
      boardButtonDisabled: getJobState(job, dronerJobs).context
        .boardButtonDisabled,
      state: getJobState(job, dronerJobs).sortNum,
    }))
    .sort((a: any, b: any) => {
      return a.state - b.state;
    });
}

export function getJobState(
  job: any,
  dronerTenders: any,
  localizedStrings: ModifiedJobStates = []
) {
  const todayStart = new Date(new Date().setHours(0, 0, 0, 0));
  const todayEnd = new Date(new Date().setHours(23, 59, 59, 0));
  const offerStart = new Date(new Date(job.offerStart).setHours(0, 0, 0, 0));
  const offerDeadline = new Date(
    new Date(job.offerDeadline).setHours(23, 59, 59, 0)
  );
  const uploadDeadline = new Date(
    new Date(job.uploadDeadline).setHours(23, 59, 59, 0)
  );

  const jobOffers = dronerTenders.filter((o: any) => o.tender === job.id);

  const jobStateArray: originalJobStates = [
    {
      name: "expiredAndNotApplied",
      sortNum: 11,
      cond: offerDeadline <= todayEnd && jobOffers.length === 0,
      context: {
        boardButtonColor: "success",
        oneJobButtonColor: "success",
        boardButtonDisabled: true,
        oneJobButtonDisabled: true,
        canMakOffer: false,
      },
    },
    {
      name: "expiredAndNotUploaded",
      sortNum: 10,
      cond:
        uploadDeadline <= todayEnd &&
        !jobOffers.some((o: any) => !!o.workStatus),
      context: {
        boardButtonColor: "success",
        oneJobButtonColor: "success",
        boardButtonDisabled: true,
        oneJobButtonDisabled: true,
        canMakOffer: false,
      },
    },
    {
      name: "paymentSent",
      sortNum: 7,
      cond: jobOffers.some((o: any) => o.paymentStatus === "sent"),
      context: {
        boardButtonColor: "success",
        oneJobButtonColor: "success",
        boardButtonDisabled: false,
        oneJobButtonDisabled: true,
        canMakOffer: false,
      },
    },
    {
      name: "workWaiting",
      sortNum: 3,
      cond: jobOffers.some((o: any) => o.workStatus === "waiting"),
      context: {
        boardButtonColor: "warning",
        oneJobButtonColor: "warning",
        boardButtonDisabled: false,
        oneJobButtonDisabled: true,
        canMakOffer: false,
      },
    },
    {
      name: "workAccepted",
      sortNum: 5,
      cond: jobOffers.some((o: any) => o.workStatus === "accepted"),
      context: {
        boardButtonColor: "success",
        oneJobButtonColor: "success",
        boardButtonDisabled: false,
        oneJobButtonDisabled: true,
        canMakOffer: false,
      },
    },
    {
      name: "workRejected",
      sortNum: 1,
      cond: jobOffers.some((o: any) => o.workStatus === "rejected"),
      context: {
        boardButtonColor: "error",
        oneJobButtonColor: "error",
        boardButtonDisabled: false,
        oneJobButtonDisabled: true,
        canMakOffer: false,
      },
    },
    {
      name: "offerWaiting",
      sortNum: 4,
      cond: jobOffers.some((o: any) => o.offerStatus === "waiting"),
      context: {
        boardButtonColor: "warning",
        oneJobButtonColor: "warning",
        boardButtonDisabled: false,
        oneJobButtonDisabled: true,
        canMakOffer: false,
      },
    },
    {
      name: "offerAccepted",
      sortNum: 6,
      cond: jobOffers.some((o: any) => o.offerStatus === "accepted"),
      context: {
        boardButtonColor: "success",
        oneJobButtonColor: "success",
        boardButtonDisabled: false,
        oneJobButtonDisabled: false,
        canMakOffer: false,
      },
    },
    {
      name: "offerRejected",
      sortNum: 2,
      cond: jobOffers.some((o: any) => o.offerStatus === "rejected"),
      context: {
        boardButtonColor: "error",
        oneJobButtonColor: "error",
        boardButtonDisabled: false,
        oneJobButtonDisabled: false,
        canMakOffer: true,
      },
    },
    {
      name: "giveOffer",
      sortNum: 8,
      cond: offerStart <= todayStart,
      context: {
        boardButtonColor: "success",
        oneJobButtonColor: "success",
        boardButtonDisabled: false,
        oneJobButtonDisabled: false,
        canMakOffer: true,
      },
    },
    {
      name: "notOpenYet",
      sortNum: 9,
      cond: offerStart >= todayStart,
      context: {
        boardButtonColor: "success",
        oneJobButtonColor: "success",
        boardButtonDisabled: true,
        oneJobButtonDisabled: true,
        canMakOffer: false,
      },
    },
  ];

  const modifiedLocStrings: ModifiedJobStates = localizedStrings.map((s) => {
    return {
      ...s,
      context: {
        boardButtonText: s.boardButtonText,
        oneJobButtonText: s.oneJobButtonText,
      },
    };
  });

  const jobStatesMerged = merge(
    keyBy(jobStateArray, "name"),
    keyBy(modifiedLocStrings, "name")
  );
  const localizedJobStateArray: finalJobStates = values(jobStatesMerged);

  for (const state of localizedJobStateArray) {
    if (state.cond) {
      return state;
    }
  }
  return localizedJobStateArray[localizedJobStateArray.length - 1];
}

export async function setupActDroner(user: any, userDoc: any) {
  const actDroner = new DronerModel({
    uid: user?.uid,
    email: user?.email,
    data: userDoc.data(),
  });
  await actDroner.fetchActiveSubscriptions();
  await actDroner.fetchSubscriptionLevel();
  await actDroner.fetchEmailVerified(user);
  return actDroner;
}

export function getDatabase() {
  return new FirebaseDatabase();
}

export const globalTimeOut = 4000;

export const changeLocale = (
  setActLocale: any,
  locale: string,
  router: NextRouter
) => {
  const { pathname, asPath, query } = router;
  setActLocale(locale);
  router.push({ pathname, query }, asPath, { locale }).then(() => {
    router.reload();
  });
};

export const randomChar = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  return characters.charAt(Math.floor(Math.random() * characters.length));
};
