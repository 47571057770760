import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import theme from "../styles/theme";
import { Avatar, Box } from "@mui/material";
import { useUserData } from "../hooks/useUserData";
import CloseIcon from "@mui/icons-material/Close";

export default function HamburgerIcon({
  open,
  onClick,
}: {
  open?: boolean;
  onClick?: any;
}) {
  const { user, actDroner } = useUserData();
  return (
    <>
      <Box
        className={`rounded-all-large flex align-items-center pointer bg-transparent gap-normal p-small ${
          !!user && "border-text-lighten1-tiny"
        }`}
        onClick={onClick}
      >
        {open ? (
          // @ts-ignore
          <CloseIcon sx={{ color: theme.palette.textColors.main }} />
        ) : (
          // @ts-ignore
          <MenuRoundedIcon sx={{ color: theme.palette.textColors.main }} />
        )}
        {!!user && (
          <Avatar sx={{ width: 28, height: 28 }} src={actDroner?.profImage} />
        )}
      </Box>
    </>
  );
}
