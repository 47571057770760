import { Footer } from "./Sections/Footer";
import { AppBar, Box, Container } from "@mui/material";
import { CompareItemsBar } from "./CompareItemsBar";
import Link from "next/link";
import Navbar from "./Navbar";

export default function Layout({ children }) {
  return (
    <>
      <Navbar />
      <main>
        <Box>{children}</Box>
      </main>
      <CompareItemsBar />
      <Footer />
    </>
  );
}

export function NoFooterLayout({ children }) {
  return (
    <>
      <Navbar />
      <main>
        <Box sx={{}}>{children}</Box>
      </main>
      <CompareItemsBar />
    </>
  );
}

export function NoCompareLayout({ children }) {
  return (
    <>
      <Navbar />
      <main>
        <Box sx={{ minHeight: "80vh" }}>{children}</Box>
      </main>
      <Footer />
    </>
  );
}

export function LogoOnlyLayout({ children }) {
  return (
    <>
      <Box>
        <AppBar
          position="fixed"
          className="glass-card"
          id="navbar"
          sx={{
            height: "auto",
            backgroundColor: "transparent",
            borderRadius: 0,
            boxShadow: "none",
            position: "fixed",
            top: 0,
            transition: "top 0.3s",
          }}
          elevation={0}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 1,
              height: "auto",
              py: { xs: 1, md: 1 },
              alignItems: "center",
            }}
          >
            <Link href="/">
              <Box
                sx={{
                  background:
                    "url('/assets/logo_beta.svg') no-repeat left center",
                  backgroundSize: "contain",
                  cursor: "pointer",
                  height: { xs: 15, md: 18 },
                  flexGrow: 1,
                }}
              />
            </Link>
          </Container>
        </AppBar>
      </Box>
      <main>
        <Box sx={{ minHeight: "80vh" }}>{children}</Box>
      </main>
      <CompareItemsBar />
    </>
  );
}

export function CompareOnly({ children }) {
  return (
    <>
      {children}
      <CompareItemsBar />
    </>
  );
}
