import { Box, Button } from "@mui/material";
import Link from "next/link";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import IosShareIcon from "@mui/icons-material/IosShare";
import { useContext } from "react";
import {
  CompareItemsContext,
  LocalizationContext,
  ShareLinkObject,
} from "../lib/context";
import theme from "../styles/theme";

export function MobileBottomNavigation() {
  const { localeStrings } = useContext(LocalizationContext);
  const { compareItems, setCompareItems } = useContext(CompareItemsContext);
  const { shareLinkObject, setShareLinkObject } = useContext(ShareLinkObject);

  return (
    <Box
      className="card-shadow"
      sx={{
        position: "fixed",
        zIndex: 1500,
        bottom: 0,
        left: 0,
        right: 0,
        display: { xs: "flex", md: "none" },
        justifyContent: "space-between",
        alignItems: "center",
        py: "12px",
        px: 2,
        transition: "bottom 0.3s",
        background: "rgba(255, 255, 255, 0.7)",
        backdropFilter: "blur(20px)",
        borderRadius: "10px 10px 0 0",
      }}
    >
      <Link href="/search">
        <SearchIcon
          // @ts-ignore
          sx={{ cursor: "pointer", color: theme.palette.textColors.main }}
        />
      </Link>
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          gap: 2,
        }}
      >
        <Link
          href={
            "/compare?" +
            compareItems
              ?.map((item: any, index: number) => {
                return `${index}=${item}&`;
              })
              .join("")
              .slice(0, -1)
          }
        >
          <Button variant="text" color="secondary" sx={{ m: 0, p: 0 }}>
            {localeStrings?.uiGeneral?.compare} ({compareItems?.length ?? 0})
          </Button>
        </Link>
        <DeleteIcon
          // @ts-ignore
          sx={{ color: theme.palette.textColors.main }}
          onClick={() => {
            !!setCompareItems && setCompareItems([]);
          }}
        />
      </Box>
      <IosShareIcon
        sx={{
          borderRadius: 2,
          cursor: "pointer",
          // @ts-ignore
          color: theme.palette.textColors.main,
        }}
        onClick={() => {
          if (navigator.share) {
            navigator
              .share(shareLinkObject)
              .catch((error) => console.error(error.toString()));
          }
        }}
      />
    </Box>
  );
}
