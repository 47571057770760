import { FirebaseOptions, getApp, initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { FirebasePerformance, getPerformance } from "firebase/performance";

const firebaseConfig: FirebaseOptions = JSON.parse(
  process.env.NEXT_PUBLIC_FIREBASE_CONFIG_OBJECT
);

function createFirebaseApp(config: FirebaseOptions) {
  try {
    return getApp();
  } catch (e) {
    return initializeApp(config);
  }
}

export const firebaseApp = createFirebaseApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export const functions = getFunctions(firebaseApp);
export let perf: FirebasePerformance | undefined;
if (typeof window !== "undefined") {
  perf = getPerformance(firebaseApp);
}
if (perf) {
  perf.instrumentationEnabled = true;
  perf.dataCollectionEnabled = true;
}
// connectFunctionsEmulator(functions, "localhost", 5001);

export let userLoggedIn: boolean | undefined = undefined;
onAuthStateChanged(auth, (user) => {
  userLoggedIn = !!user;
});
