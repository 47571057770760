import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

const defaultTheme = createTheme();

const primary = {
  light: "#2496cc",
  main: "#1b7098",
  dark: "#124b66",
};

const textColors = {
  light: "#b5b5b5",
  main: "#4f4f4f",
  dark: "#1c1c1c",
};

const secondary = {
  light2: "#fcc97c",
  main: "#F7931E",
  dark: "#cc7c02",
};

const paper = "white";

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat"].join(","),
    fontSize: 16,
    color: textColors.main,
  },
  shadows: Array(25).fill("none"),
  palette: {
    primary: {
      main: primary.main,
      dark: primary.dark,
      light: primary.light,
    },
    secondary: {
      main: secondary.main,
      light: secondary.light,
      dark: secondary.dark,
    },

    error: {
      main: "#DE4C3F",
    },
    success: {
      main: "#175681",
    },
    background: {
      main: "#fcfcfc",
    },
    hover: "#fafafa",
    textColors: {
      light: textColors.light,
      main: textColors.main,
      dark: textColors.dark,
    },

    paper: "#FFFFFF",
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fontWeight: "bold",
          fill: "white",
          fontSize: "1rem",
        },
      },
    },

    MuiMenu: {
      styleOverrides: {
        list: {
          padding: 0,
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "500",
          whiteSpace: "nowrap",
          fontSize: "1rem",
          lineHeight: "20px",
          padding: "8px 40px",
          borderRadius: "40px",
          textTransform: "none",
          "&.MuiButton-contained": {
            color: "white",
          },
          "&.MuiButton-text": {
            padding: "4px 8px",
          },
          "&.MuiButton-sizeSmall": {
            padding: "6px 20px",
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          transform: "scale(1,1)",
        },
      },
    },

    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: "#D0D0D0",
          textTransform: "capitalize",

          padding: "0 10px",
          borderRadius: "4px",
          "&:hover": {
            backgroundColor: "#C4C4C4",
          },
          "& .MuiToggleButtonGroup-grouped": {
            color: "white",
            border: 0,
            margin: "0 -10px",
            padding: "6px 20px",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0);",
            },
            "&.Mui-selected": {
              color: "white",
              backgroundColor: primary.light,
              "&:hover": {
                backgroundColor: primary.dark,
              },
            },
            "&.Mui-disabled": {
              border: 0,
            },
            "&:not(:first-of-type)": {
              borderRadius: "4px",
            },
            "&:first-of-type": {
              borderRadius: "4px",
            },
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          overflow: "hidden",
          height: "40px",
          borderRadius: "10px",
          paddingTop: "5px",
          paddingBottom: "5px",
        },
        multiline: {
          height: "auto",
          minHeight: "40px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: "-6px",
          zIndex: 1,
        },
        shrink: {
          top: 1,
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        fontSize: 14,
        root: {
          color: textColors.main,
          textTransform: "none",
        },
        h1: {
          fontSize: "2rem",
          fontWeight: "bold",
          margin: "8px 0",
          [defaultTheme.breakpoints.up("sm")]: {
            fontSize: "3rem",
          },
          [defaultTheme.breakpoints.up("md")]: {
            fontSize: "3.5rem",
          },
        },
        h2: {
          fontSize: "1.5rem",
          fontWeight: 700,
          margin: "4px 0",
        },
        h3: {
          fontSize: "1rem",
          fontWeight: 500,
          margin: "2px 0",
          lineHeight: "100%",
        },
        h4: {
          fontSize: "0.75rem",
          fontWeight: 700,
        },
        subtitle1: {
          fontSize: "1rem",
          fontWeight: 700,
        },

        subtitle2: {
          fontSize: "1rem",
          fontWeight: 700,
          lineHeight: "120%",
        },

        body1: {
          fontSize: "1rem",
          fontWeight: 500,
          textTransform: "none",
        },
        caption: {
          margin: 0,
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          borderRight: "1px solid",
          borderWidth: "1px",
          borderColor: secondary.light,
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          marginBottom: 10,
          boxShadow: "0 1px 20px rgba(0, 0, 0, 0.1)",

          borderRadius: 10,
          background:
            "linear-gradient(to right bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.4))",
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: paper,
          borderRadius: "10px",
          overflow: "hidden",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        select: {},
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          padding: 0,
          "& .MuiChip-root": {},
          "& .MuiOutlinedInput-root": {
            overflow: "auto",
            height: "auto",
            padding: "2px 8px",
          },
        },
        option: {},
      },
    },

    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 36,
          height: 22,
          margin: "0 12px 0 0",
          padding: 0,
          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
              transform: "translateX(14px)",
              color: "#fff",
              "& + .MuiSwitch-track": {
                opacity: 1,
                border: 0,
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
              },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              color: grey[100],
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.7,
            },
          },
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 18,
            height: 18,
            backgroundColor: "white",
          },
          "& .MuiSwitch-track": {
            borderRadius: 50,
            backgroundColor: textColors.main,
            opacity: 1,
          },
        },
      },
    },
  },
});

export default theme;
