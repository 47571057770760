import theme from "./theme";

export const glassBackground = {
  background: "rgba(255, 255, 255, 0.8)",
  boxShadow: "0 1px 20px rgba(0, 0, 0, 0.25)",
  backdropFilter: "blur(20px)",
  borderRadius: "10px",
  overflow: "hidden",
};

export const containerBackgroundWithMap = {
  position: "relative",
  background: "paper",
  height: "auto",
  minHeight: "100vh",
  "&::before": {
    [theme.breakpoints.up("md")]: {
      background: "url('../assets/worldMap.webp') no-repeat",
      backgroundSize: "cover",

      backgroundPosition: "center 80px",
      backgroundAttachment: "fixed",
    },
    content: '""',
    display: "block",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    opacity: 0.4,
  },
};

export const containerCard = {
  mx: "auto",
  width: "100%",
  background: theme.palette.paper,
  [theme.breakpoints.up("md")]: {
    ...glassBackground,
    borderRadius: 4,
  },
};

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: "90vh",
  overflow: "scroll",
  borderRadius: 2,
  outline: "none",
  background: theme.palette.background,

  [theme.breakpoints.down("md")]: {
    width: "100vw",
    height: "100%",
    borderRadius: 0,
  },
};

export const inspirationModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 702,
  height: "90vh",
  overflow: "scroll",
  borderRadius: "10px",
  outline: "none",
  background: "#fff",

  [theme.breakpoints.down("md")]: {
    width: "100vw",
    height: "100%",
    borderRadius: 0,
  },
};

export const smallModalCard = {
  backgroundColor: "paper",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: { xs: "95%", md: 700 },
  minWidth: { xs: "95%", md: 500 },
  maxHeight: "95%",
  overflowY: "auto",
  borderRadius: 4,
};

export const googleMapsInput = {
  theme: (selectTheme) => ({
    ...selectTheme,
    borderRadius: "10px",
    overflow: "hidden",
    height: "50px !important",
    border: "1px solid #b5b5b5",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
    zIndex: 20,
    colors: {
      ...selectTheme.colors,
      primary25: theme.palette.secondary.lighten1,
      primary: theme.palette.primary.main,
    },
  }),
  styles: {
    control: (provided) => ({
      ...provided,
      ...theme.components.MuiOutlinedInput.styleOverrides.root,
      backgroundColor: "transparent",
      height: "50px !important",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10,
    }),
  },
};

export const searchMapsInput = {
  theme: (selectTheme) => ({
    ...selectTheme,
    borderRadius: "10px",
    overflow: "hidden",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
    zIndex: 20,
    colors: {
      ...selectTheme.colors,
      primary25: theme.palette.secondary.lighten1,
      primary: theme.palette.primary.main,
    },
  }),

  styles: {
    container: (provided, { selectProps: { width } }) => ({
      width: width,
    }),

    control: (provided, { selectProps: { width } }) => ({
      ...provided,
      ...theme.components.MuiOutlinedInput.styleOverrides.root,
      backgroundColor: "white",
      borderWidth: 0,
      borderRadius: "20px",
      paddingLeft: 30,
      width: width,
      paddingTop: 0,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10,
    }),
  },
};

export const textLineThrough = {
  textDecoration: "line-through",
  textDecorationColor: "#de4c3f",
  textDecorationThickness: "3px",
};
