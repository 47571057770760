import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  documentId,
  DocumentSnapshot,
  FieldPath,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  query,
  setDoc,
  where,
  WhereFilterOp,
} from "firebase/firestore";
import { firebaseApp } from "../../api_config/firebase-configuration";

export class FirebaseDatabase {
  private readonly db: any = null;
  public readonly documentId = documentId();

  constructor() {
    this.db = getFirestore(firebaseApp);
  }

  getAndSubscribeToCollection(
    collectionName: string,
    setList: (list: any[]) => void
  ) {
    try {
      const unsubscribe = onSnapshot(
        query(collection(this.db, collectionName)),
        (querySnapshot) => {
          const list: any = [];
          querySnapshot.forEach((doc) => {
            list.push({ ...doc.data(), uid: doc.id });
          });
          setList(list);
        }
      );
      return Promise.resolve(undefined);
    } catch (e) {
      throw e;
    }
  }

  async getAll(
    condition: {
      path: string | FieldPath;
      operation: WhereFilterOp;
      value: any;
    } | null = null,
    maxNum: number | null = null,
    ...pathSegments: string[]
  ): Promise<any> {
    const constraints: any[] = [];

    if (condition) {
      constraints.push(
        where(condition?.path, condition?.operation, condition?.value)
      );
    }
    if (maxNum) {
      constraints.push(limit(maxNum));
    }
    const list: any[] = [];
    let querySnapshot = null;

    try {
      querySnapshot = await getDocs(
        query(collection(this.db, pathSegments.join("/")), ...constraints)
      );

      querySnapshot?.forEach((doc) => {
        list.push({ ...doc.data(), uid: doc.id });
      });
      return list;
    } catch (e) {
      throw e;
    }
  }

  async addDocWithRandomId(
    collectionName: string,
    itemWithoutId: any
  ): Promise<any> {
    try {
      return await addDoc(collection(this.db, collectionName), itemWithoutId);
    } catch (e) {
      throw e;
    }
  }

  async addDocWithId(
    itemWithId: any,
    collectionName: string,
    docId: string,
    options: { merge: boolean } = {
      merge: false,
    }
  ): Promise<any> {
    try {
      return await setDoc(
        doc(this.db, collectionName, docId),
        itemWithId,
        options
      );
    } catch (e) {
      throw e;
    }
  }

  async updateDoc(collectionName: string, item: any): Promise<any> {
    return Promise.resolve(undefined);
  }

  async addToArray(
    field: string,
    data: any,
    ...pathSegments: string[]
  ): Promise<any> {
    try {
      const docRef = doc(this.db, pathSegments.join("/"));
      return await setDoc(
        docRef,
        { [field]: arrayUnion(data) },
        { merge: true }
      );
    } catch (e) {
      throw e;
    }
  }

  async removeFromArray(
    field: string,
    data: any,
    ...pathSegments: string[]
  ): Promise<any> {
    try {
      const docRef = doc(this.db, pathSegments.join("/"));
      return await setDoc(
        docRef,
        { [field]: arrayRemove(data) },
        { merge: true }
      );
    } catch (e) {
      throw e;
    }
  }

  async getDocWithId(...pathSegments: string[]): Promise<DocumentSnapshot> {
    try {
      return await getDoc(doc(this.db, ...pathSegments));
    } catch (e) {
      throw e;
    }
  }

  async getCollectionLength(...pathSegments: string[]) {
    try {
      const querySnapshot = await getDocs(
        query(collection(this.db, pathSegments.join("/")))
      );
      return querySnapshot.size;
    } catch (e) {
      console.error("error getting count: ", e);
    }
  }
}
