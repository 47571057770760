import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useState } from "react";
import { useRouter } from "next/router";
import { getLocName } from "../lib/global";
import LanguageIcon from "@mui/icons-material/Language";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { changeLocale } from "../lib/global2";

export default function SmallLanguageSelector() {
  const [isOpen, setIsOpen] = useState(false);

  const router = useRouter();
  const { locale, locales, defaultLocale, asPath } = useRouter();
  const [actLocale, setActLocale] = useState(locale);

  function handleClick() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="w-full text-text mb-huge">
      <div className="pointer flex" onClick={handleClick}>
        <div className="flex align-items-center">
          <LanguageIcon />
          <p className="ml-normal subtitle-4 capitalize flex align-items-center">
            {getLocName(locale)}
          </p>
          {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </div>
      </div>
      {isOpen && (
        <div className="mt-normal px-normal flex col gap-tiny ">
          {locales?.map((locale: string, i: number) => (
            <div
              key={locale}
              className="flex align-items-center pointer gap-normal justify-content-center"
              onClick={() => {
                changeLocale(setActLocale, locale, router);
              }}
            >
              {actLocale === locale ? (
                <CheckCircleOutlineIcon />
              ) : (
                <RadioButtonUncheckedIcon />
              )}
              <p className="my-normal capitalize">{getLocName(locale)}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
