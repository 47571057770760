import theme from "../styles/theme";
import useSWR from "swr";
import { usersFetcher } from "./clientFetcher";
import {
  AuthProvider,
  browserSessionPersistence,
  FacebookAuthProvider,
  fetchSignInMethodsForEmail,
  GoogleAuthProvider,
  inMemoryPersistence,
  setPersistence,
  signInWithPopup,
  User,
} from "firebase/auth";
import {
  auth,
  db,
  functions,
  storage,
} from "../../api_config/firebase-configuration";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import imageCompression from "browser-image-compression";
import { dcContent } from "../../services/content";
import { fetchPostJSON } from "./apiHelpers";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { NextRouter } from "next/router";
import { HttpsCallableResult } from "@firebase/functions-types";

export function getActBreakpoint() {
  const width = window.innerWidth;
  const breakpoints = theme.breakpoints.values;
  if (width < breakpoints.sm) return "xs";
  if (width < breakpoints.md) return "sm";
  if (width < breakpoints.lg) return "md";
  if (width < breakpoints.xl) return "lg";
  else return "xl";
}

export default function getDroners() {
  const { data, error } = useSWR("usersPublic", usersFetcher);
  const loading = !data && !error;

  return {
    loading,
    droners: data,
  };
}

function compareDroners(place: { lat: number; lng: number }) {
  return function (
    a: { lat: number; lng: number },
    b: { lat: number; lng: number }
  ) {
    const distA = getDistance(place.lat, place.lng, a.lat, a.lng, "km");
    const distB = getDistance(place.lat, place.lng, b.lat, b.lng, "km");
    if (distA < distB) return -1;
    if (distA > distB) return 1;
    /* if (a.username < b.username) return -1;
    if (a.username > b.username) return 1;*/
    return 0;
  };
}

export function sortDroners(
  selectedDroners: { lat: number; lng: number }[],
  place: { lat: number; lng: number }
) {
  return selectedDroners.sort(compareDroners(place));
}

export function getDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit: string
) {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const radlon1 = (Math.PI * lon1) / 180;
  const radlon2 = (Math.PI * lon2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === "km") {
    dist = dist * 1.609344;
  }
  if (unit === "N") {
    dist = dist * 0.8684;
  }
  return Math.round(dist);
}

export const getLocNames = (locales: any[]) => {
  return locales.map((loc) => ({
    language: getLocName(loc),
    locale: loc,
  }));
};

export function getLocName(loc: any) {
  return new Intl.DisplayNames([loc], { type: "language" }).of(
    loc.slice(0, 2).toUpperCase()
  );
}

export const setUserPersistence = async (remember: boolean) => {
  remember
    ? await setPersistence(auth, browserSessionPersistence)
    : await setPersistence(auth, inMemoryPersistence);
};

export async function signInWithProvider(
  provider: AuthProvider,
  setOpen: any = null
) {
  signInWithPopup(auth, provider)
    .then((result) => {
      if (setOpen) {
        setOpen(false);
      }
      return "success";
    })
    .catch((error) => {
      console.error("auth error: ", error);
      if (error.code === "auth/account-exists-with-different-credential") {
        return handleExistingProviderError(error.customData);
      }
      if (!error.toString().includes("TypeError")) {
        return alert("Error: " + error.code);
      }
    });
}

const supportedProviders = [
  GoogleAuthProvider.PROVIDER_ID,
  FacebookAuthProvider.PROVIDER_ID,
  // TwitterAuthProvider.PROVIDER_ID,
];

const handleExistingProviderError = async (userData: { email: string }) => {
  let existingProviders;
  try {
    existingProviders = await fetchSignInMethodsForEmail(auth, userData.email);
    const firstProviderFound = existingProviders.find((p: any) =>
      supportedProviders.includes(p)
    );
    if (!firstProviderFound) {
      return alert("provider not supported");
    }
    return alert("User exists with provider: " + firstProviderFound);
  } catch (e: any) {
    alert(e.toString());
  }
};

export function getDistanceInUnit(
  distance: number,
  unitFrom: string,
  unitTo: any
) {
  const factor = 0.621371;

  if (unitFrom === unitTo) return distance;
  else if (unitFrom === "km") return Math.round(distance * factor);
  else return Math.round(distance / factor);
}

export function inputLengthError(data: string | any[], maxLength = 100) {
  if (!data) return "write something";
  else if (data.length > maxLength) return dcContent.errors.inputTooLong;
  return null;
}

export function emailRegExError(email: string) {
  return /.+@.+\..+/.test(email) ? null : dcContent.errors.wrongSyntax;
}

export function telReExError(email: string) {
  return /.+@.+\..+/.test(email) ? null : dcContent.errors.wrongSyntax;
}

// export function usernameTaken(username) {}

export function minMaxError(value: number, min: number, max: number) {
  if (!value) return "add value";
  if (value < min) return `min ${min}`;
  if (value > max) return `max ${max}`;
  return null;
}

export function arrayLengthError(
  arr: string | any[],
  min: number,
  max: number
) {
  if (!arr) arr = [];
  if (arr.length < min) return `min ${min}`;
  if (arr.length > max) return `max ${max}`;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].length > max) return dcContent.errors.inputTooLong;
  }
  return null;
}

export async function uploadToFbStorage(
  file: File | Blob | ArrayBuffer | undefined,
  path: string | undefined
) {
  if (file) {
    try {
      const fileRef = ref(storage, path);
      const snapshot = await uploadBytes(fileRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      throw error;
    }
  }
}

export async function compressImage(
  imageFile: File,
  heightAndWidth?: number | undefined
) {
  // console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: heightAndWidth ?? 960,
    useWebWorker: true,
    fileType: "image/webp",
  };
  try {
    return await imageCompression(imageFile, options);
  } catch (error) {
    console.error(error);
  }
}

export function isMobileDevice() {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator?.userAgent
    )
  ) {
    return true;
  } else {
    return false;
  }
}

export function getUrl(image: Blob | MediaSource) {
  try {
    return URL.createObjectURL(image);
  } catch {
    return image;
  }
}

export const sendRating = async (
  project: {
    id: string;
    username: string;
    clientName: string;
    title: string;
    email: string;
  },
  clientEmail: string,
  feedback: any
) => {
  const docRef = doc(db, "projects", project.id);
  const feedbackRecieved = httpsCallable(
    functions,
    "sendgrid-userFeedbackRecieved"
  );
  try {
    await setDoc(docRef, { feedback: feedback }, { merge: true });
    await feedbackRecieved({
      username: project.username,
      clientName: project.clientName,
      projectId: project.id,
      projectTitle: project.title,
      clientEmail: clientEmail,
      email: project.email,
    });
  } catch (e) {
    console.error(e);
    return null;
  }
};

export async function deleteProfile(
  user: User | null | undefined,
  dronerModel: { username: any },
  router: string[] | NextRouter
) {
  try {
    const accountDeleteFn = httpsCallable(
      functions,
      "sendgrid-accountDeleteInstantly"
    );
    await accountDeleteFn({
      uid: user?.uid,
      username: dronerModel.username,
      email: user?.email,
    });
    await auth.signOut();
    router.push("/");
  } catch (e: any) {
    console.error(e);
    alert("error: " + e.message);
  }
}

export async function cancelSubscription(
  dronerModel: {
    uid: string;
    username: string;
    projects: any;
    activeSubscriptions: { uid: string }[];
  },
  router: NextRouter
) {
  try {
    const cancelResFn = httpsCallable(functions, "payment-cancelSubscription");
    const cancelRes: HttpsCallableResult = await cancelResFn({
      uid: dronerModel.uid,
      username: dronerModel.username,
      projects: dronerModel.projects,
      subscription: dronerModel.activeSubscriptions[0].uid,
    });
    if (cancelRes.data.status === "success") {
      router.reload();
    } else if (cancelRes.data.status === "error") {
      return alert(cancelRes.data.message);
    }
  } catch (e: any) {
    console.error(e);
    alert("error: " + e.message);
  }
}

export async function fetchProjects(uid: string) {
  let projIds = [];
  const docSnap = await getDoc(doc(db, "usersPublic", uid ?? "null"));

  if (docSnap.exists()) {
    projIds = docSnap.data()?.projects;
  } else {
    // doc.data() will be undefined in this case
  }

  const temp = [];
  if (projIds) {
    for (const projId of projIds) {
      const docSnap = await getDoc(doc(db, "projects", projId));
      temp.push(!!docSnap.data() && { id: projId, ...docSnap.data() });
    }
  }
  return temp;
}

export async function fetchAllUsersProjects() {
  const projects: any[] = [];
  const docsSnap = await getDocs(collection(db, "projects"));

  // How to get data with limit
  // const q = query(collection(db, "projects"), limit(5));
  // const docsSnap = await getDocs(q);

  docsSnap.forEach((item) => projects.push(item.data()));

  const projectsWithImages = projects.filter(
    (item) => item.images && item.images.length > 0
  );

  return projectsWithImages;
}
