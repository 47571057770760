import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../api_config/firebase-configuration";

export async function usersFetcher(url) {
  const q = await query(collection(db, url), where("deleted", "!=", true));
  const querySnapshot = await getDocs(q);
  let users = [];
  querySnapshot.forEach((doc) => {
    if (doc.data().username) users.push({ uid: doc.id, ...doc.data() });
  });

  const tempFirst = users.filter((a) => !!a.profImage);
  const tempSecond = users.filter((a) => !a.profImage);

  users = [...tempFirst, ...tempSecond];

  return users;
}
