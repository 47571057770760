import { createContext, Dispatch, SetStateAction } from "react";
import { DronerModel } from "../../repository/entities/DronerModel";
import {
  AuthHandlerPage,
  ChatPage,
  ComparePage,
  CredentialsForm,
  DetailedSearchPage,
  DiscountPage,
  DiscountsPage,
  DronersPage,
  ELearningPage,
  EnterPage,
  ErrorPage,
  EventsPage,
  HomePage,
  InspirationsPage,
  InsurancePage,
  JobPage,
  JobsPage,
  PricingPage,
  ProfilePage,
  ProjectDetailsForm,
  RegisterDetailsForm,
  RegisterPage,
  SettingsPage,
  UiGeneral,
} from "../types/cmsLocalizationTypes";
import { ModifiedJobStates } from "../types/jobTypes";
import { Event } from "../types/eventTypes";

export const EventsContext = createContext<{
  events: Event[];
  setEvents: Dispatch<SetStateAction<Event[]>>;
}>({
  events: [],
  setEvents: () => {},
});

export const PlaceContext = createContext<{
  place: { lat: number; lng: number } | null;
  setPlace: Dispatch<SetStateAction<any>> | null;
}>({
  place: null,
  setPlace: null,
});

export const CompareItemsContext = createContext<{
  compareItems: any[];
  setCompareItems: any;
}>({
  compareItems: [],
  setCompareItems: () => {},
});

export const UserContext = createContext<{
  user: any;
  userData: any;
  dronerModel: DronerModel | null;
  actDroner: any;
  loading: any;
}>({
  user: null,
  userData: null,
  dronerModel: null,
  actDroner: null,
  loading: null,
});

export const DronersContext = createContext<{
  allDroners: any[];
  setAllDroners: any;
}>({
  allDroners: [],
  setAllDroners: () => {},
});

export const SelectedSkillContext = createContext<{
  selectedSkills: any[];
  setSelectedSkills: any;
}>({
  selectedSkills: [],
  setSelectedSkills: () => {},
});

export const SelectedDroner = createContext<{
  selectedDroner: any;
  setSelectedDroner: any;
}>({
  selectedDroner: null,
  setSelectedDroner: () => {},
});

export const ShareLinkObject = createContext<{
  shareLinkObject: any;
  setShareLinkObject: any;
}>({
  shareLinkObject: null,
  setShareLinkObject: () => {},
});

export const LocalizationContext = createContext<{
  localeStrings: {
    eLearningPage: ELearningPage;
    registerPage: RegisterPage;
    homePage: HomePage;
    comparePage: ComparePage;
    projectDetailsForm: ProjectDetailsForm;
    registerDetailsForm: RegisterDetailsForm;
    errorPage: ErrorPage;
    insurancePage: InsurancePage;
    pricingPage: PricingPage;
    enterPage: EnterPage;
    inspirationsPage: InspirationsPage;
    settingsPage: SettingsPage;
    jobsPage: JobsPage;
    detailedSearchPage: DetailedSearchPage;
    uiGeneral: UiGeneral;
    profilePage: ProfilePage;
    authHandlerPage: AuthHandlerPage;
    jobPage: JobPage;
    credentialsForm: CredentialsForm;
    jobStates: ModifiedJobStates;
    dronersPage: DronersPage;
    discountsPage: DiscountsPage;
    discountPage: DiscountPage;
    chatPage: ChatPage;
    eventsPage: EventsPage;
  };
}>({
  localeStrings: {
    eLearningPage: <ELearningPage>{},
    registerPage: <RegisterPage>{},
    homePage: <HomePage>{},
    comparePage: <ComparePage>{},
    projectDetailsForm: <ProjectDetailsForm>{},
    registerDetailsForm: <RegisterDetailsForm>{},
    errorPage: <ErrorPage>{},
    insurancePage: <InsurancePage>{},
    pricingPage: <PricingPage>{},
    enterPage: <EnterPage>{},
    inspirationsPage: <InspirationsPage>{},
    settingsPage: <SettingsPage>{},
    jobsPage: <JobsPage>{},
    detailedSearchPage: <DetailedSearchPage>{},
    uiGeneral: <UiGeneral>{},
    profilePage: <ProfilePage>{},
    authHandlerPage: <AuthHandlerPage>{},
    jobPage: <JobPage>{},
    credentialsForm: <CredentialsForm>{},
    jobStates: <ModifiedJobStates>[],
    dronersPage: <DronersPage>{},
    discountsPage: <DiscountsPage>{},
    discountPage: <DiscountPage>{},
    chatPage: <ChatPage>{},
    eventsPage: <EventsPage>{},
  },
});
