import { Box, Container, Typography } from "@mui/material";
import Image from "next/legacy/image";
import { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../lib/context";
import TagManager from "react-gtm-module";

export function Cookies() {
  const { localeStrings } = useContext(LocalizationContext);
  const [agreed, setAgreed] = useState(true);

  const updateGTM = (agreed: boolean) => {
    TagManager.dataLayer({
      dataLayer: [
        "consent",
        "update",
        {
          analytics_storage: agreed ? "granted" : "denied",
        },
      ],
    });
  };

  function handleAgree() {
    localStorage.setItem("agreed", "true");
    updateGTM(true);
    setAgreed(true);
  }

  useEffect(() => {
    const localAgreed = localStorage.getItem("agreed");
    const agreed = !!(localAgreed === "true");
    updateGTM(agreed);
    setAgreed(agreed);
  }, []);

  if (agreed) return <></>;

  return (
    <Box
      sx={{
        zIndex: 100,
        position: "fixed",
        bottom: 0,
        width: "100%",
        py: 1,
        pb: 2,
      }}
      className="bg-surface card-shadow"
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box
          sx={{
            width: 60,
            height: 60,
            position: "relative",
            overflow: "hidden",
            flex: "none",
          }}
        >
          <Image
            alt="cookies"
            src="/assets/cookie.svg"
            sizes="(min-width: 100vw) 100vw,
                  (min-width: 100vw) 100vw,
                 100vw"
            layout="fill"
            objectFit="cover"
            quality={50}
          />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          {/* @ts-ignore*/}
          <Typography
            variant="h3"
            color="textColors"
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            {localeStrings?.uiGeneral?.cookiesTitle}
          </Typography>
          {/* @ts-ignore*/}
          <Typography
            color="textColors"
            variant="caption"
            sx={{ textAlign: { xs: "center", md: "right" } }}
          >
            {localeStrings?.uiGeneral?.cookiesDescription}{" "}
            <a
              className="text-text"
              target="blank"
              href="/assets/docs/General_Terms_And_Conditions_Official.pdf"
            >
              {localeStrings?.uiGeneral?.termsAndConditionsLink}
            </a>{" "}
            {localeStrings?.uiGeneral?.and}{" "}
            <a
              target="blank"
              href="/assets/docs/GDPR_EN.pdf"
              className="text-text"
            >
              {localeStrings?.uiGeneral?.gdprLink}
            </a>
            .
          </Typography>
        </Box>
        <Box sx={{ whiteSpace: "nowrap", flex: "none" }}>
          <button data-variant="primary-light" onClick={() => handleAgree()}>
            {localeStrings?.uiGeneral?.iAgree}
          </button>
        </Box>
      </Container>
    </Box>
  );
}
