import { Box } from "@mui/material";
import SmallLanguageSelector from "./SmallLanguageSelector";
import SmallMenuList from "./SmallMenuList";
import { MergedNavbarItem } from "../types/mergedLocTypes";

export function HamburgerMenu({
  navbarGeneralMenuItems,
  navbarProfileMenuItems,
  handleIconClick,
}: {
  handleIconClick: any;
  navbarGeneralMenuItems: MergedNavbarItem[];
  navbarProfileMenuItems: MergedNavbarItem[];
}) {
  return (
    <Box
      className="p-normal"
      sx={{
        overflow: "auto",
        maxHeight: "100vh",
        backgroundColor: "#fff",
        position: "absolute",
        width: "100%",
        top: "50px",
      }}
    >
      <div className="mb-large justify-content-center">
        <SmallLanguageSelector />
      </div>
      <SmallMenuList
        handleIconClick={handleIconClick}
        navbarProfileMenuItems={navbarProfileMenuItems}
        navbarGeneralMenuItems={navbarGeneralMenuItems}
      />
    </Box>
  );
}
