import { Breakpoint, useMediaQuery, useTheme } from "@mui/material";

export function useScreenSize(
  size: number | Breakpoint,
  direction: "up" | "down"
) {
  const theme = useTheme();
  if (direction === "up") {
    return useMediaQuery(theme.breakpoints.up(size));
  }
  if (direction === "down") {
    return useMediaQuery(theme.breakpoints.down(size));
  }
}
