import { useEffect, useState } from "react";

export function useCompareItems() {
  const [compareItems, setCompareItems] = useState([]);

  useEffect(() => {
    let storage;
    const localStorageItems = localStorage.getItem("compareArray");
    if (localStorageItems !== null && localStorageItems.length !== 0) {
      storage = JSON.parse(localStorage.getItem("compareArray") ?? "");
    }
    setCompareItems(storage ? storage : []);
  }, []);

  useEffect(() => {
    localStorage.setItem("compareArray", JSON.stringify(compareItems));
  }, [compareItems]);
  return { compareItems, setCompareItems };
}
