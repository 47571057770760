import { ProductModel } from "./ProductModel";
import { BaseEntity } from "../BaseEntity";
import { locationType } from "../../src/lib/types";

export class DronerModel extends BaseEntity {
  public readonly uid: string | undefined = undefined;
  public email: string | undefined = undefined;
  public subscriptionLevel: undefined | string = undefined;
  public username: string | undefined = undefined;
  public displayName: string | undefined = undefined;
  public emailVerified = false;
  public activeSubscriptions: any[] = [];
  bio: string | undefined = undefined;
  equipment: string[] | undefined = undefined;
  profImage: string | undefined = undefined;
  backgroundImage: string | undefined = undefined;
  projects: string[] = [];
  public freeDist: number | undefined = undefined;
  public maxRange: number | undefined = undefined;
  public contactEmail: string | undefined = undefined;
  public contactPhone: string | undefined = undefined;
  public baseLocation: locationType | undefined = undefined;
  public skills: string[] = [];
  public activeProduct: ProductModel | undefined = undefined;
  public providerData: string[] = [];
  public discounts: any[] = [];
  public discountsPerMonth = 0;

  constructor(item: any) {
    super("users");
    this.uid = item.uid;
    this.email = item.email;
    this.addData(item.data);
  }

  public addData(data: any) {
    if (!data) return;
    this.email = data.email;
    this.username = data.username;
    this.displayName = data.displayName;
    this.freeDist = data.freeDis;
    this.maxRange = data.maxRang;
    this.contactEmail = data.contactEmail;
    this.contactPhone = data.contactPhone;
    this.baseLocation = data.baseLocation;
    this.skills = data.skills;
    this.projects = data.projects;
    this.subscriptionLevel = data.subscriptionLevel;
    this.discounts = data.discounts;
    this.discountsPerMonth =
      data.subscriptionLevel === "játsszunk 3d-ben!" ||
      data.subscriptionLevel === "enterprise"
        ? 5
        : data.subscriptionLevel === "standard"
        ? 1
        : 0;
  }

  async fetchActiveSubscriptions() {
    try {
      const items = await this.db.getAll(
        {
          path: "status",
          operation: "in",
          value: ["trialing", "active"],
        },
        null,
        this.collection,
        this.uid ?? "",
        "subscriptions"
      );
      items.forEach((item: any) => {
        this.addActiveSubscription(item);
      });
    } catch (e) {
      console.error("cannot fetch active subscription");
      throw e;
    }
  }

  async fetchSubscriptionLevel() {
    try {
      if (!this.activeSubscriptions.length) return;
      const item = await this.db.getDocWithId(
        "products",
        this.activeSubscriptions[this.activeSubscriptions.length - 1][
          "product"
        ]["id"]
      );
      this.subscriptionLevel = item?.data()?.name?.toLowerCase()
        ? item?.data()?.name?.toLowerCase()
        : "free";
    } catch (e) {
      // console.warn("activeSubscriptions: " + this.activeSubscriptions);
      console.error("cannot fetch fetchSubscriptionLevel: " + e);

      throw e;
    }
  }

  async fetchEmailVerified(user: any) {
    if (user.uid === this.uid) {
      let token;
      try {
        token = await user?.getIdTokenResult(true);
      } catch (e) {
        console.error(e);
      }
      this.emailVerified = !!token?.claims?.email_verified;
    }
    return;
  }

  addActiveSubscription(subscription: any) {
    this.activeSubscriptions.push(subscription);
  }
}
