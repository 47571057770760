import { getDatabase } from "../src/lib/global2";

export abstract class BaseEntity {
  protected db = getDatabase();
  protected readonly collection;

  protected constructor(collection: string) {
    this.collection = collection;
  }
}
