import { useUserData } from "../hooks/useUserData";
import Link from "next/link";
import { MergedNavbarItem } from "../types/mergedLocTypes";
import { useRouter } from "next/router";
import { Box } from "@mui/material";

export default function LargeMenuList({
  navbarGeneralMenuItems,
}: {
  navbarGeneralMenuItems: MergedNavbarItem[];
}) {
  const { user } = useUserData();
  const router = useRouter();

  return (
    <div className="flex align-items-center justify-content-center gap-large h-full">
      {navbarGeneralMenuItems.map(
        (item: MergedNavbarItem, i) =>
          ((!user && item.forLoggedOut && !item.personal) ||
            (!!user && item.forLoggedIn && !item.personal)) &&
          (item.title === "insurance" ? (
            <a
              key={i}
              target="_blank"
              href="https://biztositas.dronecakes.com"
              style={{ textDecoration: "none" }}
              rel="noreferrer"
            >
              <div className="flex pointer">
                <Box
                  sx={{ height: "auto" }}
                  className={`flex align-items-center text-center pointer subtitle-4 h-full ${
                    router.pathname === item.path
                      ? "nav-item-active"
                      : "nav-item-hover"
                  }`}
                >
                  {item.localizedText}
                </Box>
              </div>
            </a>
          ) : (
            <Link key={i} href={item.path}>
              <Box
                sx={{ height: "auto" }}
                className={`flex align-items-center text-center pointer subtitle-4 h-full ${
                  router.pathname === item.path
                    ? "nav-item-active"
                    : "nav-item-hover"
                }`}
              >
                {item.localizedText}
              </Box>
            </Link>
          ))
      )}
    </div>
  );
}
