import { Box, Container } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../api_config/firebase-configuration";
import { useContext, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useUserData } from "../hooks/useUserData";
import { LocalizationContext } from "../lib/context";

export function VerifyEmailTopBar() {
  const { localeStrings } = useContext(LocalizationContext);
  const { actDroner } = useUserData();
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [show, setShow] = useState(true);
  const resendVerFn = httpsCallable(
    functions,
    "sendgrid-resendEmailVerification"
  );
  const handleResendEmail = async () => {
    setLoading(true);
    try {
      const resp: any = await resendVerFn({
        email: actDroner?.email,
      });
      if (resp.data && resp.data.status === "error") {
        alert(
          "Email verification has been sent to your mailbox." +
            "In case you have not received, please try again again later."
        );
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };
  if (!show) return <></>;

  return (
    <Box className=" d-block p-fixed top-huge w-full" style={{ zIndex: 4000 }}>
      <Container>
        <div className="bg-surface elevation-normal rounded-all-normal py-normal px-large w-full d-flex p-relative align-items-center justify-content-space-between flex-wrap">
          <div className="flex col gap-small">
            <p className="text-text">
              {localeStrings.uiGeneral.confirmEmailTitle}
            </p>
            <p className="caption text-text my-normal">
              {localeStrings.uiGeneral.yourEmailAddress}: {actDroner?.email}
            </p>
          </div>
          {emailSent ? (
            <div className="text-text">
              {localeStrings.uiGeneral.confirmationEmailSentText}
            </div>
          ) : (
            <LoadingButton
              loading={loading}
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => handleResendEmail().then(() => setEmailSent(true))}
            >
              {localeStrings.uiGeneral.resendConfirmationEmailButton}
            </LoadingButton>
          )}
          <div className="p-absolute top-m-small right-m-small pointer">
            <div
              onClick={() => setShow(false)}
              className="rounded-all-full bg-surface card-shadow d-flex align-items-center justify-content-center"
            >
              <CloseIcon fontSize="small" />
            </div>
          </div>
        </div>
      </Container>
    </Box>
  );
}
