import { Container } from "@mui/material";
import { HamburgerMenu } from "./HamburgerMenu";
import { useContext, useEffect, useState } from "react";
import { VerifyEmailTopBar } from "./VerifyEmailTopBar";
import { useScreenSize } from "../hooks/useScreenSize";
import { LocalizationContext, ShareLinkObject } from "../lib/context";
import { useUserData } from "../hooks/useUserData";
import { dcContent } from "../../services/content";
import { MobileBottomNavigation } from "./MobileButtonNavigation";
import { globalTimeOut } from "../lib/global2";
import HamburgerIcon from "./HamburgerIcon";
import LargeMenuList from "./LargeMenuList";
import LargeNavLogo from "./LargeNavLogo";
import SmallNavLogo from "./SmallNavLogo";
import LargeLanguageSelector from "./LargeLanguageSelector";
import { ProfileDropDown } from "./ProfileDropdown";
import { keyBy, merge, values } from "lodash";
import { MergedNavbarItem } from "../types/mergedLocTypes";
import LoginPopup from "./LoginPopup";

export default function Navbar() {
  const { uiGeneral } = useContext(LocalizationContext).localeStrings;
  const { actDroner } = useUserData();
  const { shareLinkObject, setShareLinkObject } = useContext(ShareLinkObject);

  const mergedNavbarGeneralMenuItems = merge(
    keyBy(dcContent.navbarGeneralMenuItems, "title"),
    keyBy(uiGeneral?.navbarGeneralMenuItems, "title")
  );
  const mergedNavbarProfileMenuItems = merge(
    keyBy(dcContent.navbarProfileMenuItems, "title"),
    keyBy(uiGeneral?.navbarProfileMenuItems, "title")
  );
  const navbarGeneralMenuItems: MergedNavbarItem[] = values(
    mergedNavbarGeneralMenuItems
  );
  const navbarProfileMenuItems: MergedNavbarItem[] = values(
    mergedNavbarProfileMenuItems
  );

  const mdAndUp = useScreenSize("md", "up");
  const smAndUp = useScreenSize("sm", "up");
  const smAndDown = useScreenSize("sm", "down");

  const [waitingForVerification, setWaitingForVerification] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [loginPopupOpen, setLoginPopupOpen] = useState(false);

  function handleIconClick() {
    setMenuOpen(!menuOpen);
  }

  useEffect(() => {
    if (!setShareLinkObject) return;
    setShareLinkObject({
      title: dcContent.text.shareTitle,
      url: window.location.href,
    });
    setTimeout(() => {
      setWaitingForVerification(false);
    }, globalTimeOut);
  }, [setShareLinkObject]);

  return (
    <div className="p-relative w-full depth-l-5">
      <div
        className="glass-card d-grid p-fixed top-none card-shadow w-full"
        style={{
          zIndex: 200,
          height: smAndDown ? "50px" : "66px",
          background: "rgba(255, 255, 255, 0.7)",
          backdropFilter: "blur(20px)",
          transition: "top 0.3s",
        }}
      >
        {!!actDroner && !actDroner.emailVerified && !waitingForVerification && (
          <VerifyEmailTopBar />
        )}
        <Container sx={{ maxWidth: "1248px !important" }}>
          <div className={`d-flex h-full gap-normal align-items-center`}>
            {mdAndUp ? <LargeNavLogo /> : <SmallNavLogo />}
            <div className="spacer" />
            {smAndUp && (
              <LargeMenuList navbarGeneralMenuItems={navbarGeneralMenuItems} />
            )}
            <div className="flex">
              {smAndUp && <LargeLanguageSelector />}
              {smAndUp && (
                <ProfileDropDown
                  setLoginPopupOpen={setLoginPopupOpen}
                  navbarProfileMenuItems={navbarProfileMenuItems}
                />
              )}
              {smAndDown && (
                <HamburgerIcon open={menuOpen} onClick={handleIconClick} />
              )}
            </div>
          </div>
        </Container>
        {smAndDown && !!menuOpen && (
          <HamburgerMenu
            handleIconClick={handleIconClick}
            navbarProfileMenuItems={navbarProfileMenuItems}
            navbarGeneralMenuItems={navbarGeneralMenuItems}
          />
        )}
      </div>
      {smAndDown && <MobileBottomNavigation />}
      <LoginPopup open={loginPopupOpen} setOpen={setLoginPopupOpen} />
    </div>
  );
}
