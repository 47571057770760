import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  linkWithRedirect,
  OAuthProvider,
  TwitterAuthProvider,
} from "firebase/auth";
import { signInWithProvider } from "../lib/global";

export const handleButtonAction = async (
  actionType,
  user,
  providerObj,
  setOpen
) => {
  if (actionType === "login") {
    return signInWithProvider(providerObj, setOpen);
  }
  if (actionType === "link") {
    if (
      user.providerData.some((e) => e.providerId === providerObj.providerId)
    ) {
      return alert("provider already linked");
    }
    try {
      await linkWithRedirect(user, providerObj);
      return alert("successfully linked");
    } catch (e) {
      return alert(e.toString());
    }
  } else {
    return alert("action not supported");
  }
};

export const GoogleButton = ({
  action,
  text,
  user = null,
  disabled = false,
  setOpen = null,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault();
        return handleButtonAction(
          action,
          user,
          new GoogleAuthProvider(),
          setOpen
        );
      }}
      className="google-button w-full"
    >
      {text}
    </button>
  );
};
export const FacebookButton = ({
  action,
  text,
  user = null,
  disabled = false,
  setOpen = null,
}) => {
  return (
    <button
      disabled={disabled}
      className="facebook-button w-full"
      onClick={(e) => {
        e.preventDefault();
        return handleButtonAction(
          action,
          user,
          new FacebookAuthProvider(),
          setOpen
        );
      }}
    >
      {text}
    </button>
  );
};
export const TwitterButton = ({
  action,
  text,
  user = null,
  disabled = false,
  setOpen = null,
}) => {
  return (
    <button
      disabled={disabled}
      className="twitter-button w-full"
      onClick={(e) => {
        e.preventDefault();
        return handleButtonAction(
          action,
          user,
          new TwitterAuthProvider(),
          setOpen
        );
      }}
    >
      {text}
    </button>
  );
};

export const YahooButton = () => {
  return (
    <button
      className="yahoo-button"
      onClick={() => signInWithProvider(new OAuthProvider("yahoo.com"))}
    >
      yahoo
    </button>
  );
};
