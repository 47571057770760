import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { auth, db } from "../../api_config/firebase-configuration";
import { DronerModel } from "../../repository/entities/DronerModel";
import { setupActDroner } from "../lib/global2";

export function useUserData() {
  const [user] = useAuthState(auth);
  /**
   * deprecated
   */
  const [userData, setUserData] = useState<any>(null);
  /**
   * deprecated
   */
  const [dronerModel, setDronerModel] = useState<DronerModel | any>(null);
  const [actDroner, setActDroner] = useState<DronerModel | any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!user) {
      setActDroner(null);
      return;
    }
    let actUserData: any;
    const unsub: any = onSnapshot(
      doc(db, "users", user.uid),
      (userDoc: any) => {
        actUserData = {
          ...userDoc.data(),
          uid: userDoc?.id,
        };

        setUserData(actUserData);
        setupActDroner(user, userDoc).then((r) => {
          setActDroner(r);
          setDronerModel(r);
        });
      }
    );

    return (): void => unsub;
  }, [user]);

  return { user, userData, dronerModel, actDroner, loading };
}
