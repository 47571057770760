import Image from "next/legacy/image";
import { useRouter } from "next/router";

export default function SmallNavLogo() {
  const router = useRouter();
  return (
    <div onClick={() => router.push("/")} className="flex">
      <Image
        alt=""
        priority={false}
        src={"/assets/mobile-nav-logo.webp"}
        width="37"
        height="37"
        className="pointer"
      />
    </div>
  );
}
