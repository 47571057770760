import { Box, Container, Link } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useContext, useState } from "react";
import { useRouter } from "next/router";
import { LocalizationContext } from "../../lib/context";
import { Cookies } from "../Cookies";
import { getLocName } from "../../lib/global";
import { changeLocale } from "../../lib/global2";
import NewsletterSubscriptionForm from "../NewsletterSubscriptionForm";
import { keyBy, merge, values } from "lodash";

const siteMapItems = [
  {
    itemId: "drone jobs",
    link: "/jobs",
  },
  {
    itemId: "discounts",
    link: "/discounts",
  },
  {
    itemId: "inspirations",
    link: "/inspirations",
  },
  {
    itemId: "drone pilots",
    link: "",
    action: () => window.scrollTo({ top: 0, behavior: "smooth" }),
  },
  {
    itemId: "detailed search",
    link: "/search",
  },
  {
    itemId: "pricing",
    link: "/pricing",
  },
];

export const Footer = () => {
  const { uiGeneral } = useContext(LocalizationContext).localeStrings;
  const localizedSiteMapItems = uiGeneral.siteMapItems;
  const mergedSiteMapItems = values(
    merge(keyBy(siteMapItems, "itemId"), keyBy(localizedSiteMapItems, "title"))
  );
  const router = useRouter();
  const { locale, locales } = useRouter();
  const [actLocale, setActLocale] = useState(locale);

  return (
    <Box
      sx={{
        pb: 8,
        pt: 8,
      }}
      className="bg-primary-lighten2"
    >
      <Container
        sx={{
          color: "paper",
        }}
      >
        <div className="footer">
          {/* upper level*/}
          <div className="footer__upper">
            <div className="footer__left">
              <div className="footer__listBox footer__siteMap">
                <h3 className="footer__primaryText">{uiGeneral.siteMap}</h3>
                <div className="footer__listBox--main">
                  {mergedSiteMapItems.map((item, i) => (
                    <h6
                      onClick={
                        item.action ? item.action : () => router.push(item.link)
                      }
                      key={i}
                    >
                      {item.localizedText}
                    </h6>
                  ))}
                </div>
              </div>

              <div className="footer__listBox footer__languages">
                <h3 className="footer__primaryText">{uiGeneral.languages}</h3>
                <div className="footer__listBox--main">
                  {locales?.map((item: any, i: number) => (
                    <h6
                      key={i}
                      className="capitalize"
                      onClick={() => {
                        changeLocale(setActLocale, item, router);
                      }}
                    >
                      {getLocName(item)}
                    </h6>
                  ))}
                </div>
              </div>

              <div className="footer__newsletter">
                <h3 className="footer__primaryText">
                  {uiGeneral.subToNewsletter}
                </h3>
                <NewsletterSubscriptionForm />
              </div>
            </div>
            <div className="footer__social">
              <h3 className="footer__primaryText">{uiGeneral.followUs}</h3>
              <Box sx={{ display: "flex", gap: 2 }}>
                <InstagramIcon
                  sx={{ color: "white" }}
                  onClick={() =>
                    window.open("https://www.instagram.com/drncks/")
                  }
                  className="pointer"
                />
                <LinkedInIcon
                  sx={{ color: "white" }}
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/dronecakes-com1/"
                    )
                  }
                  className="pointer"
                />
                <FacebookIcon
                  sx={{ color: "white" }}
                  onClick={() => window.open("https://www.facebook.com/drncks")}
                  className="pointer"
                />
                <YouTubeIcon
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UCpfhdJ66w0uGdCm2-72byaQ"
                    )
                  }
                  className="pointer"
                  sx={{ color: "white" }}
                />
              </Box>
            </div>
          </div>

          <div className="footer__bottom">
            <div className="footer__bottom--upper">
              <h6>
                © {uiGeneral?.copyrightTitle1}. {uiGeneral?.copyrightTitle2}.
              </h6>
              <div style={{ display: "flex", columnGap: "3px" }}>
                <Link
                  href={
                    router.locale === "hu"
                      ? "/assets/docs/ászf_dronecakes.pdf"
                      : "/assets/docs/General_Terms_And_Conditions_Official.pdf"
                  }
                  underline="none"
                  children={uiGeneral?.termsAndConditionsLink}
                />
                |
                <Link
                  href={
                    router.locale === "hu"
                      ? "/assets/docs/GDPR_HU.pdf"
                      : "/assets/docs/GDPR_EN.pdf"
                  }
                  underline="none"
                  children="Privacy Statement"
                />
                |
                <Link
                  href={`mailto:${uiGeneral?.emailLink}`}
                  underline="none"
                  children={uiGeneral?.emailLink}
                />
              </div>
            </div>

            <h6 className="footer__bottom--poweredBy">
              {uiGeneral.poweredBy}{" "}
              <span onClick={() => window.open("https://www.loricatus.hu")}>
                loricatus
              </span>
            </h6>
          </div>
        </div>
      </Container>
      {/* <Consent />*/}
      <Cookies />
    </Box>
  );
};
