import { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import {
  Avatar,
  Divider,
  Menu,
  MenuItem,
  MenuList,
  Skeleton,
} from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { auth } from "../../api_config/firebase-configuration";
import theme from "../styles/theme";
import { useUserData } from "../hooks/useUserData";
import { LocalizationContext } from "../lib/context";
import { MergedNavbarItem } from "../types/mergedLocTypes";

export function ProfileDropDown({
  navbarProfileMenuItems,
  setLoginPopupOpen,
}: {
  navbarProfileMenuItems: MergedNavbarItem[];
  setLoginPopupOpen: any;
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { user, userData, actDroner, loading } = useUserData();
  const router = useRouter();
  const { localeStrings } = useContext(LocalizationContext);

  useEffect(() => {}, [actDroner]);

  function handleDropdownOpen(event: any) {
    setAnchorEl(event.currentTarget);
  }

  function handleDropdownClose() {
    setAnchorEl(null);
  }

  function handleLogOut() {
    router.push("/");
    handleDropdownClose();
    auth.signOut();
  }

  if (loading) {
    return <Skeleton width={90} height={40} />;
  }

  if (!user) {
    return (
      <div className="flex align-items-center">
        <button
          onClick={() => setLoginPopupOpen(true)}
          data-variant="primary-light"
        >
          {localeStrings.uiGeneral?.signInButton}
        </button>
      </div>
    );
  }

  return (
    <>
      <div
        className="rounded-all-huge py-small px-normal flex align-items-center pointer bg-surface gap-small border-text-lighten1-tiny"
        onClick={handleDropdownOpen}
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore*/}
        <MenuRoundedIcon sx={{ color: theme.palette.textColors.main }} />
        <Avatar sx={{ width: 28, height: 28 }} src={userData?.profImage} />
      </div>
      <Menu
        className="mt-large"
        anchorEl={anchorEl}
        open={open}
        onClose={handleDropdownClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div>
          <MenuList sx={{ py: "normal", px: "large", gap: 2 }}>
            {navbarProfileMenuItems.map(
              (item: MergedNavbarItem, i: number) =>
                ((!user && item?.forLoggedOut && !!item?.personal) ||
                  (!!user && item?.forLoggedIn && !!item?.personal)) && (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      actDroner?.username
                        ? item?.title == "view profile"
                          ? router.push(`${item?.path + actDroner?.username}`)
                          : router.push(item?.path)
                        : router.push("/" + router.locale + "/register");
                      handleDropdownClose();
                    }}
                    className="pointer flex align-items-center text-text"
                  >
                    {item?.localizedText}
                  </MenuItem>
                )
            )}
            {user && (
              <>
                <Divider />
                <MenuItem
                  onClick={handleLogOut}
                  sx={{ my: "normal" }}
                  className="pointer flex align-items-center text-text subtitle-1 px-large"
                >
                  {localeStrings?.uiGeneral?.logOutButton}
                </MenuItem>
              </>
            )}
          </MenuList>
        </div>
      </Menu>
    </>
  );
}
