import LanguageIcon from "@mui/icons-material/Language";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useState } from "react";
import { useRouter } from "next/router";
import { getLocName } from "../lib/global";
import { IconButton, Menu, MenuItem, MenuList } from "@mui/material";
import { changeLocale } from "../lib/global2";

export default function LargeLanguageSelector() {
  const router = useRouter();
  const { locale, locales } = useRouter();
  const [actLocale, setActLocale] = useState(locale);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton onClick={handleClick} sx={{ mr: 2 }}>
        {/* @ts-ignore*/}
        <LanguageIcon fontSize="small" color="textColors" />
      </IconButton>
      <Menu
        className="mt-large"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuList sx={{ py: "normal", px: "large", gap: 2 }}>
          {locales?.map((locale: string, i: number) => (
            <MenuItem
              key={locale}
              onClick={() => {
                changeLocale(setActLocale, locale, router);
              }}
              className="pointer flex align-items-center text-text"
            >
              {actLocale === locale ? (
                // @ts-ignore
                <CheckCircleOutlineIcon color="textColors" />
              ) : (
                // @ts-ignore
                <RadioButtonUncheckedIcon color="textColors" />
              )}

              <div className="capitalize link-hover ml-large  text-text">
                {getLocName(locale)}
              </div>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
}
