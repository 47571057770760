export const content_en = {
  routerLocaleToDateFn: [
    {
      locale: "en",
      dateFnLocale: "en-GB",
    },
    {
      locale: "es",
      dateFnLocale: "es",
    },
    {
      locale: "hu",
      dateFnLocale: "hu",
    },
    {
      locale: "sr-Latn-RS",
      dateFnLocale: "sr-Latn",
    },
    {
      locale: "sr-Cyrl-RS",
      dateFnLocale: "sr",
    },
  ],

  elearningCategories: [
    {
      categoryId: "3D",
      enabled: true,
    },
    {
      categoryId: "legal",
      enabled: false,
    },
    {
      categoryId: "agri",
      enabled: false,
    },
    {
      categoryId: "fpv",
      enabled: false,
    },
    {
      categoryId: "general",
      enabled: true,
    },
  ],

  skills: [
    { name: "creative", image: "/assets/skills/creative.webp" },
    { name: "survey and mapping", image: "/assets/skills/mapping.webp" },
    { name: "agricultural", image: "/assets/skills/agri.webp" },
    { name: "service", image: "/assets/skills/service.webp" },
    { name: "education", image: "/assets/skills/education.webp" },
    { name: "FPV", image: "/assets/skills/other.webp" },
    { name: "other", image: "/assets/skills/other.webp" },
  ],

  navbarProfileMenuItems: [
    {
      title: "sign in",
      path: "/enter",
      forLoggedIn: false,
      forLoggedOut: true,
      personal: true,
    },
    {
      title: "register",
      path: "/register",
      forLoggedIn: false,
      forLoggedOut: true,
      personal: true,
    },
    {
      title: "settings",
      path: "/settings",
      forLoggedIn: true,
      forLoggedOut: false,
      personal: true,
    },
    {
      title: "view profile",
      path: "/droners/",
      forLoggedIn: true,
      forLoggedOut: false,
      personal: true,
    },
  ],

  navbarGeneralMenuItems: [
    {
      title: "elearning",
      path: "/e-learning",
      forLoggedIn: true,
      forLoggedOut: true,
      personal: false,
    },
    {
      title: "events",
      path: "/events",
      forLoggedIn: true,
      forLoggedOut: true,
      personal: false,
    },
    {
      title: "drone jobs",
      path: "/jobs",
      forLoggedIn: true,
      forLoggedOut: true,
      personal: false,
    },
    {
      title: "discounts",
      path: "/discounts",
      forLoggedIn: true,
      forLoggedOut: true,
      personal: false,
    },
    {
      title: "detailed search",
      path: "/droners",
      forLoggedIn: true,
      forLoggedOut: true,
      personal: false,
    },
    {
      title: "insurance",
      path: "https://biztositas.dronecakes.com/",
      forLoggedIn: true,
      forLoggedOut: true,
      personal: false,
    },
    {
      title: "inspirations",
      path: "/inspirations",
      forLoggedIn: true,
      forLoggedOut: true,
      personal: false,
    },
  ],
  sponsors: [
    {
      img: "/assets/dji_logo.svg",
      link: "https://enterprise.dji.com/",
    },
    {
      img: "/assets/legter_logo.webp",
      link: "https://legter.hu/",
    },
    {
      img: "/assets/loricatus_logo.webp",
      link: "https://www.loricatus.hu/",
    },
  ],

  heroSection: [
    {
      heroId: "register",
      bgColor: "primary.main",
      textColor: "paper",
      buttonColor: "success",
      img: "/assets/hero_register.webp",
      link: "/register",
      secondaryLink: "/jobs",
    },
    {
      heroId: "detailed_search",
      bgColor: "paper",
      textColor: "primary.main",
      buttonColor: "success",
      link: "/search",
      img: "/assets/hero_search.webp",
    },
  ],

  buttonTexts: {
    closedBtn: "closed",
    applyBtn: "details",
    verifyEmailBtn: "confirm email",
    registerForFreeBtn: "register for free now",
    getFreeTrialBtn: "get free trial",
    refreshPage: "refresh page",
    resendVerification: "resend confirmation email",
    editBtn: "edit",
    imAmADronerBtn: "i am a drone pilot",
    termsBtn: "Terms and conditions",
    gdprBtn: "GDPR",
    reactivateBtn: "renew subscription",
    homePageBtn: { longVer: "go to homepage", shortVer: "homepage" },
    searchDronersBtn: "search drone pilots",
    backBtn: "back",
    nextBtn: "next",
    editProfileBtn: "edit profile",
    settingsBtn: "settings",
    deleteProfileBtn: "delete profile",
    viewProfileBtn: "view profile page",
    openBillingPortalBtn: "open billing portal",
    deleteBtn: "delete",
    shareBtn: "share",
    copyUrl: "copy url",
    addMoreBtn: "add more",
    cancelBtn: "cancel",
    saveBtn: "save",
    searchBtn: "search",
    searchDronePilotsBtn: "search drone pilots",
    compareBtn: "compare",
    clearBtn: "clear",
    createAccountBtn: "Create account",
    editProjectModImagesBtn: { mod: "modify images", noMod: "keep old images" },

    removeBtn: "remove",
    subscribeBtn: "subscribe",
    askForFeedbackBtn: "Ask for feedback",
    sendBtn: "Send",
    howItWoksBtn: "how it works",
    pricingBtn: "pricing",
    tendersBtn: "Drone Jobs Board",
    signInBtn: "sign in",
    signOutBtn: "sign out",
    signUpBtn: "Sign up",
    registerBtn: "register",
    profileBtn: "your profile",
    addProjectBtn: "Add project",
    emailBtn: "email",
    phoneBtn: "phone number",
    forgotPw: "forgot password",
    submitBtn: "submit",
    detailedSearchBtn: "find more pilots",
  },

  forms: {
    tenderOfferForm: [
      {
        formType: "textField",
        id: "offer",
        title: "your price",
        type: "number",
        required: true,
        requiredMessage: "enter your offer",
        tooltip: "offer tooltip",
        maxLength: 100,
        maxLengthMessage: "too long",
        pattern: /^\d*$/,
        patternMessage: "only numbers accepted",
      },
      {
        formType: "textField",
        id: "offerDescription",
        title: "offer description",
        type: "text",
        multiline: true,
        required: false,
        tooltip: "comment on your offer",
        maxLength: 100,
        maxLengthMessage: "too long",
      },
    ],

    newEmailForm: [
      {
        formType: "textField",
        id: "newEmail",
        title: "new email",
        type: "email",
        required: true,
        requiredMessage: "enter your new email address",
        tooltip: "The main email address for your account",
        maxLength: 100,
        maxLengthMessage: "too long",
        pattern: /.+@.+\..+/,
        patternMessage: "incorrect email format",
      },
      {
        formType: "textField",
        id: "oldPass",
        title: "current password",
        type: "password",
        required: true,
        requiredMessage: "enter your old password",
        tooltip: "old password",
      },
    ],

    newPassForm: [
      {
        formType: "textField",
        id: "oldPass",
        title: "current password",
        type: "password",
        required: true,
        requiredMessage: "enter your old password",
        tooltip: "old password",
      },
      {
        formType: "textField",
        id: "newPass",
        title: "new password",
        type: "password",
        required: true,
        requiredMessage: "enter your new password",
        tooltip: "new password",
        minLength: 6,
        minLengthMessage: "too weak",
        maxLength: 100,
        maxLengthMessage: "too long",
      },
    ],

    registerDetails: [
      {
        formType: "textField",
        id: "username",
        type: "text",
        required: true,
        maxLength: 50,
        minLength: 3,
        pattern: /^[\dA-Za-z._]+$/,
      },
      {
        formType: "textField",
        id: "displayName",
        type: "text",
        required: true,

        maxLength: 200,
        minLength: 3,
      },
      {
        formType: "textField",
        id: "contactEmail",
        type: "email",
        required: true,
        maxLength: 100,
        pattern: /.+@.+\..+/,
      },
      {
        formType: "textField",
        id: "tel",
        type: "tel",
        required: false,
        pattern: /^\+?[1-9]\d{1,14}$/,
      },
      {
        formType: "textField",
        id: "freeDist",
        type: "number",
        required: true,
        minValue: 0,
        maxValue: 10000,
      },
      {
        formType: "textField",
        id: "maxRange",
        type: "number",
        required: true,
        minValue: 0,
        maxValue: 10000,
      },
      {
        formType: "textField",
        id: "bio",
        type: "text",
        multiline: true,
        required: false,
        maxLength: 2000,
      },
      {
        formType: "places",
        id: "location",
        type: "text",
        required: true,
      },
      {
        formType: "select",
        id: "skills",
      },
      {
        formType: "equipment",
        id: "equipment",
      },
    ],

    projectDetails: [
      {
        formType: "textField",
        id: "title",
        type: "text",
        required: true,
      },
      {
        formType: "places",
        id: "location",
        type: "text",
        required: true,
      },
      {
        formType: "textField",
        id: "description1",
        multiline: true,
        required: false,
      },
      {
        formType: "textField",
        id: "description2",
        multiline: true,
        required: false,
      },

      {
        formType: "select",
        id: "skills",
      },
      {
        formType: "equipment",
        id: "equipment",
      },
    ],
  },

  text: {
    warnSubscriptionChange:
      "Your subscription will be changed. If you downgrade to lower rate, you will lose all your uploaded projects and and skills, and will have to manually upload new ones. To edit billing settings, go to billing tab.",

    tenders: {
      subtitle: "Make money on dronecakes instantly",
      deadline: "deadline",
      pleaseLogIn: "login to send in your work",
      onlyEnterprise: "subscribe to enterprise to access",
      title: "title",
      pageTitle: "Drone Jobs Board",
      tenderNotFound: "job not found",
      description: "description",
      scenario: "scenario",
      place: "place",
      example: "example",
      exampleDesc:
        "We would like to give you an example of what kind of work we would like to get from you",
      formDesc: "please use this form to send in your work",
      noLongerActive: "this job is not active anymore",
      form: "click here, to upload work",
      duration: "duration",
      quantity: "quantity",
      rtk: "rtk",
      rtk_yes: "yes",
      rtk_no: "no",
      height: "height",
      type: "type",
      skills: "skills",
      location: "location",
      price: "PRICE",
      status: "STATUS",
      active: "ACTIVE",
      inactive: "INACTIVE",
    },
    inspirationsTitle: "inspirations",
    remember: "stay signed in",
    logInTitle: "log in",
    makeYourProfile: "Sign up to make your own profile",
    imADronePilot: "I am a drone pilot",
    waitingForVerification: {
      title: "Confirmation link sent!",
      text: "We have sent you a confirmation link to:",
      checkText: "Check your email for a link to sign in.",
      havingTrouble: "Having trouble with verifications?",
      contactUs: "Contact us!",
    },
    askForFeedback: "Send email to your client to rate your work",
    deleteProfileSubtitle:
      "by deleting your account, all your subscription will automatically disabled and all your profile information removed",
    deleteProfileTitle: "are you sure you want to delete?",
    confirmDelete: "Are you sure you want to delete?",
    applyCoupon: "Add coupon code here",
    credentialsStep: {
      title: "register",
      subTitle: "to make your drone business visible",
    },
    detailsStep: {
      title: "business information",
      subTitle: "Last step to start your business",
    },
    pricingStep: {
      title: "subscription",
    },
    locations: "locations",
    loading: "Loading",
    email: "email",
    password: "password",
    feedback: "feedback",
    skills: "skills",
    passwordAgain: "password again",
    pleaseLogIn: "Log in to view this page",
    address: "address",
    or: "or",
    dronerNotFound: {
      pre: "The droner",
      post: "does not exist or the profile is not yet set up",
      full: " The droner does not exist or the profile is not yet set up",
    },
    thisIsPublic: "This will show up on your public profile",
    prefLang: "Preferred language",
    distanceFromYou: "distance from you",
    freeTravelDist: "free travel distance",
    maxTravelRange: "max travel range",
    equipment: "equipment",
    software: "software",
    projects: "projects",
    aboutMe: "about me",
    analytics: "analytics",
    profileViews: "profile views",
    shareTitle: "dronecakes",
    shareText: "check out",
    compareDroners: "Compare droners",
    compare: "compare",
    dronePilots: "drone pilots",
    dronePilotList: "drone pilot list",
    signin: "sign in",
    makeMoreShowMore: "See more, show more, make yourself seen more!",
    notMemberYet: "Not a member yet?",
    sponsors: "Partners of the drone pilots",
    howDoesItWorkQ: "How does it work?",
    howDoesItWorkA:
      "DroneCakes.com helps to make connections between" +
      " Drone Pilots and Customers easily. Browse their past project, compare them and contact the one best fitting.",
    pricing: "pricing",
    addNewReference: "add new reference",
    youAcceptTerms: "by registering, you accept our terms and conditions",
    alreadyHaveAccount: "Already have an account? Sign in",
    fromYou: "from you",
    help: "help",
    copyright: {
      dk: "DRONECAKES LTD. HUNGARY, 2022",
      allRights: "ALL RIGHTS RESERVED",
    },
    needCouponCode: "Need coupon code? Go to",
    useCouponCode:
      "use the **coupon code** in the next step to get **3 month free trial**",
    customerFeedback: "customer feedback",
    noFeedback: "no feedback yet",
    thankFeedback: "Thank you for your feedback",
    mapSearchBarLabel: "where you want the job to take place",
    mapSearchBarPlaceholder: "search place",
    fileUpload: {
      dragAndDrop: "Drag and drop or",
      browse: "Browse",
      uploadMedia: "upload media",
      removeSelected: "remove selected",
    },
    stepsComplete: "All steps completed - you are ready",
    verifyEmail: {
      verifyTopBarText1: "Please confirm your email address.",
      successTitle: "congratulation!",
      successText:
        "Email address has been verified. You can close this window now.",
      invalid: "Invalid or expired code, try verifying again",
    },
    passwordReset: {
      success: "Password has been successfully reset",
      invalid: "Invalid or expired code, try again",
      continueHere: "continue here",
      resetFor: "reset password for",
      modalTitle:
        "enter your account's email address and we will send you a password reset link",
    },
    marketing: {
      h2: "drone work connection",
      h3: "Place where The drone Pilots are",
    },
    inspirations: {
      h2: "Inspirations",
      h3: "check out other Drone Pilot’s work",
    },
    droners: {
      h2: "Discover Drone pilots",
      h3: "choose who suits your needs",
    },
    preview: {
      maps: {
        h2: "locations",
        h3: "click map to see drone pilots worldwide",
      },
      userCards: {
        h2: "quick results",
        h3: "see drone pilots with selected skills",
      },
    },
    heroSection: {
      title: "find drone pilots worldwide",
      subTitle: "Discover drone pilots you could hire",

      dronerType: {
        h2: "select categories",
        h3: "of droner are you searching for?",
      },
    },
    otherDroners: "all drone pilots",
    otherProjects: "see all projects",
    othersSaid: [
      {
        body:
          "0. szöveg: Contrary to popular belief," +
          " Lorem Ipsum is not simply random text." +
          " It has roots in a piece of classical Latin literature from 45 BC," +
          " making it over 2000 years old. Richard McClintock," +
          " a Latin professor at Hampden-Sydney College in Virginia," +
          " looked up one of",
        name: "0. name",
        title: "0. ügyvezető",
      },
      {
        body:
          "1. szöveg: Contrary to popular belief," +
          " Lorem Ipsum is not simply random text." +
          " It has roots in a piece of classical Latin literature from 45 BC," +
          " making it over 2000 years old. Richard McClintock," +
          " a Latin professor at Hampden-Sydney College in Virginia," +
          " looked up one of",
        name: "1. name",
        title: "1. ügyvezető",
      },
      {
        body:
          "2. szöveg: Contrary to popular belief," +
          " Lorem Ipsum is not simply random text." +
          " It has roots in a piece of classical Latin literature from 45 BC," +
          " making it over 2000 years old. Richard McClintock," +
          " a Latin professor at Hampden-Sydney College in Virginia," +
          " looked up one of",
        name: "2. name",
        title: "2. ügyvezető",
      },
    ],
    copied: "copied",
  },

  errors: {
    patterError: "field can not contain special characters",
    error: "something went wrong",
    noUserName: "no username",
    noActiveSubscription: "You don't have an active subscription.",
    noAddress: "no address",
    noDronersToCompare: "no droners to compare",
    invalidMode: "Invalid mode",
    emailMustBeVerified: "Email must be verified before proceeding",
    noData: "please wait...",
    pageNotFound: "page not found",
    notAvailable: "not available",
    cantRateSelf: "Can't rate yourself",
    notValidPlace: "please add a valid place",
    uploadLimit: "upload limit reached",
    noPhoneNumSet: "no phone number set",
    noEmailSet: "no email set",
    usernameTaken: "Username taken. Please find an other one",
    inputTooLong: "too long",
    wrongSyntax: "wrong syntax",
    freeDistMaxRange: "free dist cannot be larger then max range",
  },

  pricingLimits: [
    { priceName: "free", maxSkills: 1, maxProjects: 1, maxImages: 1 },
    { priceName: "standard", maxSkills: 3, maxProjects: 3, maxImages: 6 },
    {
      priceName: "enterprise",
      maxSkills: -1,
      maxProjects: 1000,
      maxImages: 1000,
    },
  ],

  pricingFeatures: [
    {
      text: "Tender and Job opportunity",
      enterprise: true,
      standard: false,
      free: false,
    },
    {
      text: "Unlimited skill selection",
      enterprise: true,
      standard: false,
      free: false,
    },
    {
      text: "Unlimited Projects",
      enterprise: true,
      standard: false,
      free: false,
    },
    {
      text: "Multi shot and video",
      enterprise: true,
      standard: false,
      free: false,
    },
    {
      text: "Upgraded Profile Site",
      enterprise: true,
      standard: true,
      free: false,
    },
    {
      text: "Visible Brand For Your Business",
      enterprise: true,
      standard: true,
      free: false,
    },

    {
      text: "3 Skill selection",
      enterprise: false,
      standard: true,
      free: false,
    },
    {
      text: "6 pictures / Projects",
      enterprise: false,
      standard: true,
      free: false,
    },
    {
      text: "1 Skill selection",
      enterprise: false,
      standard: false,
      free: true,
    },
    {
      text: "1 pictures / Projects",
      enterprise: false,
      standard: false,
      free: true,
    },
    {
      text: "upgraded Profile Site",
      enterprise: false,
      standard: false,
      free: true,
    },
    {
      text: "Unlimited offer submissions on Jobs Board",
      enterprise: true,
      standard: "disabled",
      free: "disabled",
    },
  ],

  howItWoks: [
    {
      text: "Select drone skill category and put the drone pilots into the comparison page.",
      image: "/assets/howItWorks/howItWorks_1.svg",
    },
    {
      text: "Based on your personal preferences comparison, choose the most impressive drone pilot.",
      image: "/assets/howItWorks/howItWorks_2.svg",
    },
    {
      text: "Contact directly the chosen drone pilot.",
      image: "/assets/howItWorks/howItWorks_3.svg",
    },
    {
      text: "“The chosen one” will satisfy your requirements.",
      image: "/assets/howItWorks/howItWorks_4.svg",
    },
  ],

  errorPage: {
    title: "An error has occurred",
    subtitle: "We apologize for the inconvenience!",
    description1:
      "We are running in BETA mode, but our developers will work to make\n" +
      "              sure that you can use the site without any bugs as soon as\n" +
      "              possible and continue building your brand without any problems.",
    description2:
      "Please come back to this site later! However, in the meantime, we\n" +
      "              welcome your comments, please report any bugs to us using the\n" +
      "              email address below and let's build the community together.",
    emailLink: "info@dronecakes.com",
    homePageBtnText: "got to homepage",
  },

  footer: {
    information: "information",
    askUs: "ask us",
    poweredBy: "powered by",
    contactEmail: "info@dronecakes.com",
    signiture: "DRONECAKES LTD. HUNGARY, 2022 -ALL RIGHTS RESERVED",
  },

  meta: {
    homePage: {
      title: "Find drone pilots worldwide here | dronecakes.com",
      description:
        "Discover and compare the skills of drone pilots and choose who suits your needs",
    },
    detailedSearch: {
      title: "Detailed search of drone pilots worldwide | dronecakes.com",
      description:
        "Advanced search among drone pilots based on your criteria and expectations",
    },
    profilePage: {
      title: "'s drone page | dronecakes.com",
      description:
        "Direct access to the drone pilot who can be entrusted with the tasks you need.",
    },
    register: {
      title: "Log In/Sign Up | dronecakes.com",
      description:
        "Join a global community of drone pilots on Dronecakes.com. Log in with your email address, Facebook, Twitter, Yahoo or Google.",
    },
    enter: {
      title: "Log In/Sign Up | dronecakes.com",
      description:
        "Join a global community of drone pilots on Dronecakes.com. Log in with your email address, Facebook, Twitter, Yahoo or Google.",
    },
    inspirations: {
      title: "Inspiration by global drone pilots workflow | dronecakes.com",
      description:
        "Discover tips to help you make your drone business profitable and provide friendly service to your customers.",
    },
    pricing: {
      title: "Pricing plans | dronecakes.com",
      description:
        "Start your business with your drone today. Create a profitable and visible brand",
    },
    compare: {
      title: "Compare page of drone pilots | dronecakes.com",
      description:
        "Compare the drone pilots market to find a better deal on your issues and finances",
    },
  },
};

export const priceImages = {
  enterprise: "/assets/enterprise_drone.webp",
  standard: "/assets/standard_drone.webp",
  free: null,
};
