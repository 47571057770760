import * as React from "react";
import { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Head from "next/head";
import { CssBaseline } from "@mui/material";
import { CacheProvider } from "@emotion/react";
import createEmotionCache from "../src/lib/createEmotionCache";
import Layout from "../src/components/Layout";
import ContextProvider from "../src/lib/contextProvider";
import "../src/styles/global.scss";
import { DefaultSeo } from "next-seo";
import { cmsContent } from "../services/content";
import { useRouter } from "next/router";
import Honeybadger from "@honeybadger-io/js";
import { LocalizationContext } from "../src/lib/context";
import { AppProps } from "next/app";
import TagManager from "react-gtm-module";

const clientSideEmotionCache = createEmotionCache();

export default function MyApp(
  props: AppProps & { emotionCache: any; Component: { Layout: any } }
) {
  const uiGeneral = useContext(LocalizationContext).localeStrings.uiGeneral;
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const PageLayout = Component.Layout || Layout;
  const { asPath } = useRouter();

  if (process.env.NEXT_PUBLIC_ACT_ENV === "production") {
    Honeybadger.configure({
      apiKey: process.env.NEXT_PUBLIC_HONEYBADGER_API_KEY,
      environment: process.env.NODE_ENV,
      reportData: true,
    });
  }

  const canonicalUrl = (
    `https://${process.env.NEXT_PUBLIC_DOMAIN_NAME}` +
    (asPath === "/" ? "" : asPath)
  ).split("?")[0];

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GTM_ID) {
      TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID });
      TagManager.dataLayer({
        dataLayer: [
          "consent",
          "default",
          {
            analytics_storage: "denied",
            region: ["EU", "US-CA"],
          },
          {
            analytics_storage: "granted",
          },
        ],
      });
    }
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>DroneCakes</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link
          rel="preconnect"
          href="https://www.googletagmanager.com"
          crossOrigin="anonymous"
        />
        <link
          rel="preconnect"
          href="https://www.google-analytics.com"
          crossOrigin="anonymous"
        />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="anonymous"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <DefaultSeo
        canonical={canonicalUrl}
        robotsProps={{
          nosnippet: false,
          notranslate: false,
          noimageindex: false,
          noarchive: false,
        }}
        openGraph={{
          type: "website",
          title: uiGeneral.defaultSeoTitle,
          description: uiGeneral.defaultSeoDescription,
          images: [
            {
              url: "/assets/index_thumbnail.webp",
              width: 1667,
              height: 1667,
              alt: "dronecakes logo",
            },
          ],
        }}
        facebook={{
          appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
        }}
        twitter={{
          handle: "@handle",
          site: "@site",
          cardType: "summary_large_image",
        }}
      />

      <ContextProvider props={{ ...props, cmsContent }}>
        <PageLayout>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Component {...pageProps} />
        </PageLayout>
      </ContextProvider>
    </CacheProvider>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
};
